import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";



// Styles
const Container = Styled.div`
    display: flex;
    padding: 6px 8px;
    gap: 6px;
    justify-content: space-between;
    align-items: center;
    border-top: var(--border-width) dashed var(--border-color-light);
    background-color: var(--lightest-gray);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
`;

const Actions = Styled.div`
    display: flex;
    gap: 6px;
`;

const Line = Styled.div`
    width: 1px;
    height: 24px;
    background-color: var(--border-color-light);
`;

const Text = Styled.p`
    margin: 0;
    font-size: 12px;
    color: var(--darkest-gray);
`;


/**
 * The Reply Actions
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReplyActions(props) {
    const {
        msgTemplateMenuRef, emojiMenuRef, fileMenuRef,
        variableMenuRef, channelMenuRef, msgTemplates,
        text, maxLength, onFormat, onMenu, onDialog,
    } = props;

    const { variables, assistants } = Store.useState("conversation");


    // Do the Render
    const characters = String(text || "").length;

    return <Container>
        <Actions>
            <IconLink
                passedRef={emojiMenuRef}
                variant="black"
                icon="emoji"
                tooltip="EMOJI_NAME"
                tooltipVariant="top"
                onClick={() => onMenu("emoji")}
                isSmall
            />
            <IconLink
                variant="black"
                icon="bold"
                message="GENERAL_FORMAT_BOLD"
                onClick={() => onFormat("**")}
                isSmall
            />
            <IconLink
                variant="black"
                icon="italic"
                message="GENERAL_FORMAT_ITALIC"
                onClick={() => onFormat("__")}
                isSmall
            />
            <IconLink
                variant="black"
                icon="strikethrough"
                message="GENERAL_FORMAT_STRIKETHROUGH"
                onClick={() => onFormat("~~")}
                isSmall
            />
            <Line />
            <IconLink
                isHidden={!msgTemplates.length}
                passedRef={msgTemplateMenuRef}
                variant="black"
                icon="template"
                tooltip="GENERAL_TEMPLATE"
                tooltipVariant="top"
                onClick={() => onMenu("msgTemplate")}
                isSmall
            />
            <IconLink
                passedRef={fileMenuRef}
                variant="black"
                icon="attachment"
                tooltip="GENERAL_FILE"
                tooltipVariant="top"
                onClick={() => onMenu("file")}
                isSmall
            />
            <IconLink
                isHidden={!variables.length}
                passedRef={variableMenuRef}
                variant="black"
                icon="variable"
                tooltip="GENERAL_VARIABLES"
                tooltipVariant="top"
                onClick={() => onMenu("variable")}
                isSmall
            />
            <IconLink
                passedRef={channelMenuRef}
                variant="black"
                icon="channel"
                tooltip="CHANNELS_SINGULAR"
                tooltipVariant="top"
                onClick={() => onMenu("channel")}
                isSmall
            />
            <IconLink
                isHidden={!assistants.length}
                variant="black"
                icon="assistant"
                tooltip="ASSISTANTS_SINGULAR"
                tooltipVariant="top"
                onClick={() => onDialog("assistant")}
                isSmall
            />
        </Actions>
        <Text>
            {`${characters}/${maxLength}`}
        </Text>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReplyActions.propTypes = {
    msgTemplateMenuRef : PropTypes.any.isRequired,
    emojiMenuRef       : PropTypes.any.isRequired,
    fileMenuRef        : PropTypes.any.isRequired,
    variableMenuRef    : PropTypes.any.isRequired,
    channelMenuRef     : PropTypes.any.isRequired,
    msgTemplates       : PropTypes.array.isRequired,
    text               : PropTypes.string.isRequired,
    maxLength          : PropTypes.number.isRequired,
    onFormat           : PropTypes.func.isRequired,
    onMenu             : PropTypes.func.isRequired,
    onDialog           : PropTypes.func.isRequired,
};

export default ReplyActions;
