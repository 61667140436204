import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import useList              from "Dashboard/Hooks/List";
import { App }              from "Utils/API";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";



/**
 * The Action Log List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ActionLogList(props) {
    const { type } = props;
    const { load, loadFilter } = useList("actionLog", type);

    const params = Navigate.useParams();

    const data = Store.useState("actionLog");
    const { loading, list, total, filters, sort } = data;


    // Renders the List
    const renderContent = () => {
        const result = [];
        let   index  = 0;

        for (const elem of list) {
            result.push(<TableRow key={index}>
                <TableCell colSpan="4">
                    <b className="right-space-big">{elem.timeText}</b>
                    <b className="right-space-big">{NLS.get("ACTIONS_LOG_SESSION")}</b>
                    <i className="right-space-big">{elem.credentialName}</i>
                    <span title={elem.userAgent}>{elem.ip}</span>
                </TableCell>
            </TableRow>);
            index++;

            for (const action of elem.actions) {
                result.push(<TableRow key={index}>
                    <TableCell message={action.timeText}   />
                    <TableCell message={action.moduleName} />
                    <TableCell message={action.actionName} />
                    <TableCell message={action.dataText}   />
                </TableRow>);
                index++;
            }

            if (!elem.isLast) {
                result.push(<TableRow key={index}>
                    <TableCell colSpan="4">{"\u00A0"}</TableCell>
                </TableRow>);
            }
            index++;
        }
        return result;
    };


    // Do the Render
    return <Main>
        <Header message="ACTIONS_LOG_NAME" icon="action-log" />
        <Content>
            <Filter
                onFilter={loadFilter}
                hasCredential={type !== "PROFILE"}
                fetch={App.searchUser}
                params={{
                    inUsers  : type === "USER" ? 1 : 0,
                    clientID : params.clientID,
                }}
                values={filters}
            />
            <Table
                fetch={load}
                sort={sort}
                none="ACTIONS_LOG_NONE_AVAILABLE"
                isLoading={loading}
                hasFilter
                noSorting
            >
                <TableHead>
                    <TableHeader field="time"   message="GENERAL_TIME"   />
                    <TableHeader field="module" message="GENERAL_MODULE" />
                    <TableHeader field="action" message="GENERAL_ACTION" />
                    <TableHeader field="dataID" message="GENERAL_ID"     />
                </TableHead>
                <TableBody>
                    {renderContent()}
                </TableBody>
                <TablePaging total={total} />
            </Table>
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ActionLogList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ActionLogList;
