import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";

// Components
import SettingsTitle        from "./SettingsTitle";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";

// Images
import LightMode            from "Styles/Images/Light.svg";
import DarkMode             from "Styles/Images/Dark.svg";



// Styles
const List = Styled.ul`
    display: flex;
    gap: 12px;
    margin: 12px 0 0 0;
    padding: 0;
    list-style: none;
`;

const Item = Styled.li.attrs(({ isSelected }) => ({ isSelected }))`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: var(--main-gap);
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
    transition: all 0.2s;
    cursor: pointer;

    :hover {
        border-color: var(--border-color-dark);
    }
    ${(props) => props.isSelected && "border-color: var(--border-color-dark);"}
`;

const Image = Styled.img`
    width: 100%;
    border-radius: var(--border-radius);
    overflow: hidden;
`;

const Inside = Styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .icon {
        color: var(--primary-color);
    }
`;



/**
 * The Settings Appearance
 * @returns {React.ReactElement}
 */
function SettingsAppearance() {
    const { isDark } = Store.useState("auth");
    const { changeAppearance } = Store.useAction("profile");


    // Do the Render
    return <section>
        <SettingsTitle
            icon="appearance"
            title="ACCOUNT_APPEARANCE_TITLE"
        />

        <List>
            <Item
                isSelected={!isDark}
                onClick={() => changeAppearance("light")}
            >
                <Image
                    src={LightMode}
                    alt={NLS.get("ACCOUNT_APPEARANCE_LIGHT")}
                />
                <Inside>
                    {NLS.get("ACCOUNT_APPEARANCE_LIGHT")}
                    <Icon
                        icon={!isDark ? "radio-on" : "radio-off"}
                        size="20"
                    />
                </Inside>
            </Item>

            <Item
                isSelected={isDark}
                onClick={() => changeAppearance("dark")}
            >
                <Image
                    src={DarkMode}
                    alt={NLS.get("ACCOUNT_APPEARANCE_DARK")}
                />
                <Inside>
                    {NLS.get("ACCOUNT_APPEARANCE_DARK")}
                    <Icon
                        icon={isDark ? "radio-on" : "radio-off"}
                        size="20"
                    />
                </Inside>
            </Item>
        </List>
    </section>;
}

export default SettingsAppearance;
