import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Conversation Transfer Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationTransfer(props) {
    const { open, clientID, conversationID, onSubmit, onClose } = props;

    const { elem, users, teams, usersInTeams } = Store.useState("conversation");
    const { transferConversation } = Store.useAction("conversation");


    // The Initial Data
    const initialData = {
        conversationID : 0,
        teamID         : 0,
        userID         : 0,
    };

    // Handles the Set
    const handleSet = (data) => {
        setElem({
            ...data,
            teamID : elem.teamID,
            userID : elem.userID,
        });
    };

    // Handles the Edit
    const handleEdit = () => {
        return transferConversation({ ...data, conversationID });
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("conversation", initialData, handleEdit, onSubmit);

    // Load the Data
    useDialog("conversation", open, 0, { clientID }, handleSet);


    // Generate the users
    const usersList = React.useMemo(() => {
        if (teams.length <= 1 || !data.teamID) {
            return users;
        }
        const userIDs = usersInTeams?.[data.teamID] || [];
        return users.filter(({ key }) => userIDs.includes(Number(key)));
    }, [ teams.length, data.teamID ]);


    // Do the Render
    const showTeams = teams.length > 1;

    return <EditDialog
        open={open}
        icon="user"
        title="CONVERSATIONS_TRANSFER_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <DialogMessage
            message={showTeams ? "CONVERSATIONS_TRANSFER_TEXT" : "CONVERSATIONS_TRANSFER_USER_TEXT"}
        />

        <InputField
            isHidden={!showTeams}
            type="select"
            name="teamID"
            label="TEAMS_SINGULAR"
            options={teams}
            value={data.teamID}
            error={errors.teamID}
            onChange={handleChange}
            noneText="GENERAL_NONE"
            noneValue={0}
            shrinkLabel
        />
        <InputField
            type="select"
            name="userID"
            label="USERS_SINGULAR"
            options={usersList}
            value={data.userID}
            error={errors.userID}
            onChange={handleChange}
            isRequired
            hideClear
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationTransfer.propTypes = {
    open           : PropTypes.bool.isRequired,
    clientID       : PropTypes.number.isRequired,
    conversationID : PropTypes.number.isRequired,
    onSubmit       : PropTypes.func.isRequired,
    onClose        : PropTypes.func.isRequired,
};

export default ConversationTransfer;
