import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Alert Type Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AlertTypeEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { ticketTypes } = Store.useState("ticketAlertType");
    const { editElem } = Store.useAction("ticketAlertType");


    // The Initial Data
    const initialData = {
        ticketAlertTypeID : 0,
        ticketTypeID      : 0,
        alertCode         : "",
        subject           : "",
        message           : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("ticketAlertType", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("ticketAlertType", open, elemID, {}, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="alert-type"
        title={elemID ? "TICKET_ALERT_TYPES_EDIT_TITLE" : "TICKET_ALERT_TYPES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="alertCode"
            label="TICKET_ALERT_TYPES_ALERT_CODE"
            helperText="TICKET_ALERT_TYPES_ALERT_CODE_TIP"
            value={data.alertCode}
            error={errors.alertCode}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="ticketTypeID"
            label="TICKET_TYPES_SINGULAR"
            options={ticketTypes}
            value={data.ticketTypeID}
            error={errors.ticketTypeID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="subject"
            label="GENERAL_SUBJECT"
            helperText="TICKET_ALERT_TYPES_VARIABLES_TIP"
            value={data.subject}
            error={errors.subject}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="message"
            label="GENERAL_MESSAGE"
            helperText="TICKET_ALERT_TYPES_VARIABLES_TIP"
            value={data.message}
            error={errors.message}
            onChange={handleChange}
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AlertTypeEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default AlertTypeEdit;
