import React                from "react";
import Store                from "Dashboard/Core/Store";

// Dashboard
import BarIcon              from "Dashboard/Components/Core/BarIcon";



/**
 * The User Menu
 * @returns {React.ReactElement}
 */
function UserMenu() {
    const { isClientUser, isSupervisor, isOwner } = Store.useState("auth");
    const {
        hasSubscriptions, hasHospitality, hasStore, hasAnyStore,
        hasAccount, hasMarketing,
    } = Store.useState("permission");

    const { closeMenu } = Store.useAction("core");


    // Variables
    const showStore         = Boolean(hasStore && (hasAnyStore || isSupervisor || isOwner));
    const showMarketing     = Boolean(hasMarketing && (isSupervisor || isOwner));
    const showUsers         = Boolean(isSupervisor || isOwner);
    const showSettings      = Boolean(isSupervisor || isOwner);
    const showSubscriptions = Boolean(hasSubscriptions && isOwner);


    // Do the Render
    if (!isClientUser) {
        return <React.Fragment />;
    }
    return <>
        <BarIcon
            url="DASHBOARD"
            icon="dashboard"
            message="DASHBOARD_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="CONVERSATIONS"
            icon="conversation"
            message="CONVERSATIONS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            isHidden={!showStore}
            url="STORES"
            icon="store"
            message="STORES_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            url="CONTACTS"
            icon="contact"
            message="CONTACTS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            isHidden={!hasHospitality}
            url="HOSPITALITIES"
            icon="hospitality"
            message="HOSPITALITY_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            isHidden={!hasAccount}
            url="ACCOUNTS"
            icon="account"
            message="ACCOUNTS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            isHidden={!showMarketing}
            url="CAMPAIGNS"
            icon="campaign"
            message="CAMPAIGNS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            isHidden={!showUsers}
            url="USERS"
            icon="user"
            message="USERS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            isHidden={!showUsers}
            url="TEAMS"
            icon="team"
            message="TEAMS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            isHidden={!showSubscriptions}
            url="SUBSCRIPTIONS"
            icon="subscription"
            message="SUBSCRIPTIONS_NAME"
            onClick={closeMenu}
            withText
            withTooltip
            startsWith
        />
        <BarIcon
            isHidden={!showSettings}
            url="SETTINGS"
            icon="settings"
            message="GENERAL_SETTINGS"
            withText
            withTooltip
            startsWith
        />
    </>;
}

export default UserMenu;
