import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";

// Components
import ChatReaction         from "./ChatReaction";
import ChatForward          from "./ChatForward";
import ChatMessages         from "./ChatMessages";
import ChatSummary          from "./ChatSummary";
import ReplyContainer       from "../Reply/ReplyContainer";



// Styles
const Container = Styled.section`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: var(--page-height);
    gap: var(--main-gap);
    overflow: hidden;
`;

const Title = Styled.h3`
    margin: 0 var(--main-padding);
    font-size: 16px;
    text-align: center;

    :empty {
        display: none;
    }
`;

const Messages = Styled.section`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-left: 8px;
    padding-bottom: 16px;
    overflow: auto;
`;



/**
 * The Chat Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ChatContainer(props) {
    const { isUnreadRef } = props;
    const { conversationHash } = Navigate.useParams();

    const clientID = Hooks.useClientID();

    const { isAnyAdmin, credentialID } = Store.useState("auth");
    const { elem, elemModified } = Store.useState("conversation");
    const { lastUpdate, items, messages, hasMore } = Store.useState("conversationMessage");

    const { fetchElem, setLastView, markAsRead } = Store.useAction("conversation");
    const { fetchOldMessages, fetchNewMessages } = Store.useAction("conversationMessage");


    // The References
    const messagesRef = React.useRef(null);
    const unreadRef   = React.useRef(null);
    const timerRef    = React.useRef(0);
    const timeoutRef  = React.useRef(0);
    const firstRef    = React.useRef(null);

    // The Current State
    const [ atBottom,     setAtBottom     ] = React.useState(true);
    const [ replyToID,    setReplyToID    ] = React.useState(0);
    const [ replyMessage, setReplyMessage ] = React.useState("");
    const [ forwardToID,  setForwardToID  ] = React.useState(0);
    const [ reactToID,    setReactToID    ] = React.useState(0);
    const [ reactionTop,  setReactionTop  ] = React.useState(0);
    const [ reactionLeft, setReactionLeft ] = React.useState(0);


    // Load the Data
    React.useEffect(() => {
        if (conversationHash) {
            setAutoUpdate();
            fetchElem(conversationHash, clientID);
            setReplyToID(0);
            isUnreadRef.current = 0;
        }
    }, [ conversationHash, elemModified ]);

    // Handle the Auto-update
    React.useEffect(() => {
        if (lastUpdate) {
            setAutoUpdate();
        }
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, [ conversationHash, lastUpdate, credentialID ]);

    // Handle the Conversation change
    React.useEffect(() => {
        Utils.clearTimeout(timeoutRef);
        firstRef.current = null;

        if (conversationHash && elem.id) {
            if (!isAnyAdmin) {
                setLastView(conversationHash, clientID);
            }
            if (!isAnyAdmin && elem.isUnread) {
                Utils.setTimeout(timeoutRef, () => {
                    markAsRead(conversationHash, clientID);
                }, 2 * 1000);
            }

            scrollToUnread(true);
            window.setTimeout(() => scrollToUnread(true), 200);
        }
    }, [ conversationHash, elem.id ]);

    // Handle the Conversation View
    React.useEffect(() => {
        if (conversationHash && elem.id && !isAnyAdmin) {
            window.addEventListener("mousemove", updateLastView);
        }
        return () => {
            window.removeEventListener("mousemove", updateLastView);
        };
    }, [ conversationHash, elem.id ]);

    // Removes the Last view
    React.useEffect(() => {
        if (!isAnyAdmin) {
            window.addEventListener("beforeunload", () => setLastView("", 0));
        }
        return () => {
            if (!isAnyAdmin) {
                window.removeEventListener("beforeunload", () => setLastView("", 0));
                setLastView("", 0);
            }
        };
    }, []);


    // Updates the Last View
    let waitUntil = 0;
    const updateLastView = () => {
        if (Date.now() >= waitUntil) {
            setLastView(conversationHash, clientID);
            waitUntil = Date.now() + 10 * 1000;
        }
    };

    // Sets the Auto-update
    const setAutoUpdate = () => {
        Utils.clearTimeout(timerRef);
        if (conversationHash) {
            if (atBottom) {
                window.setTimeout(() => scrollToUnread(false), 100);
            }
            Utils.setTimeout(timerRef, () => {
                fetchNewMessages(conversationHash, clientID, lastUpdate, isUnreadRef.current);
            }, 5 * 1000);
        }
    };


    // Scrolls to the Unread Messages
    const scrollToUnread = (firstScroll) => {
        const node = unreadRef.current;
        if (node) {
            const parent = node.parentNode;
            parent.scrollTo({
                top      : node.offsetTop - parent.offsetHeight / 2,
                behavior : firstScroll ? "instant" : "smooth",
            });
        } else {
            scrollToBottom(firstScroll);
        }
    };

    // Scrolls to the Bottom of the Chat
    const scrollToBottom = (firstScroll) => {
        Utils.scrollToBottom(messagesRef, firstScroll);
    };

    // Handles the Scroll
    const handleScroll = async (e) => {
        let atBottom = false;

        if (e.target.scrollTop === 0 && hasMore) {
            if (!firstRef.current) {
                firstRef.current = messages[0];
            }

            const first    = firstRef.current;
            const result   = await fetchOldMessages(elem.id, first.createdTime);
            const newFirst = result.messages.length ? result.messages[0] : 0;

            // Restore the scroll at the last top
            if (newFirst) {
                firstRef.current = newFirst;
                window.setTimeout(() => {
                    /** @type {HTMLElement} */
                    const oldNode = document.querySelector(`#conversation-${first.messageID}`);
                    /** @type {HTMLElement} */
                    const newNode = document.querySelector(`#conversation-${newFirst.messageID}`);

                    messagesRef.current.scrollTop = oldNode.offsetTop - newNode.offsetTop;
                }, 10);
            }
        } else if (e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight) - 50) {
            atBottom = true;
        }
        setAtBottom(atBottom);
    };

    // Handles the Reply
    const handleReply = (messageID = 0, message = "") => {
        setReplyToID(messageID);
        setReplyMessage(message);
    };

    // Handles the Forward
    const handleForward = (messageID = 0) => {
        setForwardToID(messageID);
    };

    // Handles the Reaction
    const handleReaction = (messageID = 0, bounds = {}) => {
        setReactToID(messageID);
        setReactionTop(bounds.top);
        setReactionLeft(bounds.left);
    };


    // Do the Render
    return <>
        <Container>
            <Title>{elem.title}</Title>

            <Messages ref={messagesRef} onScroll={handleScroll}>
                {items.map((item) => <ChatMessages
                    key={item.key}
                    elem={item}
                    unreadRef={unreadRef}
                    canReplyTo={elem.canReplyTo}
                    onReply={handleReply}
                    canReactTo={elem.canReactTo}
                    onReaction={handleReaction}
                    canForwardTo={elem.canForwardTo}
                    onForward={handleForward}
                />)}
                <ChatSummary elem={elem} />
            </Messages>

            <ReplyContainer
                clientID={clientID}
                conversationHash={conversationHash}
                replyToID={replyToID}
                replyMessage={replyMessage}
                onReplyRemove={handleReply}
                onSubmit={scrollToBottom}
            />
        </Container>

        <ChatReaction
            reactToID={reactToID}
            top={reactionTop}
            left={reactionLeft}
            onClose={() => setReactToID(0)}
        />
        <ChatForward
            open={!!forwardToID}
            messageID={forwardToID}
            clientID={elem.clientID}
            title={elem.title}
            onClose={() => setForwardToID(0)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ChatContainer.propTypes = {
    isUnreadRef : PropTypes.object.isRequired,
};

export default ChatContainer;
