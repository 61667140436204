import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import HyperLink            from "Dashboard/Components/Link/HyperLink";
import Html                 from "Dashboard/Components/Common/Html";

// Images
import Login                from "Styles/Images/Login.jpg";
import Logo                 from "Styles/Images/Logo.png";



// Styles
const Container = Styled.main`
    display: grid;
    grid-template-columns: 480px 440px;
    min-height: 500px;
    background-color: var(--content-color);
    border-radius: var(--border-radius-big);
    color: rgb(94, 108, 132);
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
    overflow: hidden;

    @media (max-width: 1000px) {
        display: block;
        max-width: 440px;
        min-height: 0;
        margin: 24px;
    }
`;

const Hero = Styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media (max-width: 1000px) {
        display: none;
    }
`;

const Content = Styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 48px;
    gap: 16px;

    @media (max-width: 450px) {
        padding: 24px;
    }
`;

const Title = Styled.h1`
    margin: 0;
    a {
        display: block;
    }
`;

const Image = Styled.img`
    display: block;
    width: 100%;
`;

const Inside = Styled.section`
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
`;

const Terms = Styled(Html)`
    margin: 16px 0 -8px 0;
    padding: 0;
    text-align: center;
    font-size: 0.8em;
    color: rgba(21, 28, 41, 0.48);
`;

const Version = Styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 10px;
    opacity: 0.5;
`;



/**
 * The Auth Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AuthContainer(props) {
    const { children } = props;


    // Do the Render
    return <>
        <Container>
            <Hero src={Login} />
            <Content>
                <Title>
                    <HyperLink variant="none" url="LOGIN">
                        <Image src={Logo} alt={NLS.get("TITLE")} />
                    </HyperLink>
                </Title>
                <Inside>
                    {children}
                </Inside>
                <Terms>
                    {NLS.format("LOGIN_TERMS", process.env.REACT_APP_TERMS, process.env.REACT_APP_PRIVACY)}
                </Terms>
            </Content>
        </Container>
        <Version>{process.env.REACT_APP_VERSION}</Version>
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AuthContainer.propTypes = {
    children : PropTypes.any,
};

export default AuthContainer;
