import React                from "react";
import PropTypes            from "prop-types";

// Dashboard
import DetailItem           from "Dashboard/Components/Details/DetailItem";



/**
 * The Fields Detail Items
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function FieldsItems(props) {
    const { fields } = props;


    // Do the Render
    if (!fields || !fields.length) {
        return <React.Fragment />;
    }
    return <>
        {fields.map((field) => <DetailItem
            key={field.customFieldID}
            icon="info"
            message={field.value}
            prefix={field.name}
            url={field.url}
            target="_blank"
        />)}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
FieldsItems.propTypes = {
    fields : PropTypes.array,
};

export default FieldsItems;
