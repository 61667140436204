import React                 from "react";
import PropTypes             from "prop-types";
import Store                 from "Dashboard/Core/Store";
import Navigate              from "Dashboard/Core/Navigate";

// Dashboard
import ActionItem            from "Dashboard/Components/Header/ActionItem";
import ActionOption          from "Dashboard/Components/Header/ActionOption";
import MenuItem              from "Dashboard/Components/Menu/MenuItem";



/**
 * The Conversation Actions
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationActions(props) {
    const { isUnreadRef, setOpenDialog } = props;

    const { conversationHash } = Navigate.useParams();

    const { hasAccount } = Store.useState("permission");

    const {
        canEdit, canDelete, elem, contact, providers,
        tags, tongues, hotels, flows, assistants,
    } = Store.useState("conversation");

    const {
        markAsUnread, assignTag, assignTongue, assignHotel, assignFlow,
    } = Store.useAction("conversation");


    // Generate the Tags List
    const tagList = React.useMemo(() => {
        return [{ key : 0, value : "TAGS_CREATE_TITLE" }, ...tags ];
    }, [ JSON.stringify(tags) ]);

    // Generate the Hotels List
    const hotelList = React.useMemo(() => {
        const result = [ ...hotels ];
        if (elem.hotelID) {
            result.unshift({ key : 0, value : "GENERAL_NONE" });
        }
        return result;
    }, [ JSON.stringify(hotels), elem.hotelID ]);


    // Handles the Unread Action
    const handleUnread = () => {
        if (elem.isProgress || elem.isFollowup) {
            markAsUnread(elem.id);
            isUnreadRef.current = 1;
        }
    };

    // Handles the Tag Action
    const handleTag = (tagID) => {
        if (!tagID) {
            setOpenDialog("tag");
        } else {
            assignTag(elem.id, tagID);
        }
    };

    // Handles the Tongue Action
    const handleTongue = (tongueID) => {
        if (tongueID !== elem.tongueID) {
            assignTongue(elem.id, tongueID);
        }
    };

    // Handles the Hotel Action
    const handleHotel = (hotelID) => {
        if (hotelID !== elem.hotelID) {
            assignHotel(elem.id, hotelID);
        } else {
            assignHotel(elem.id, 0);
        }
    };

    // Handles the Flow Action
    const handleFlow = (flowID) => {
        assignFlow(elem.id, flowID);
    };


    // Variables
    const canMarkUnread    = Boolean(elem.isProgress || elem.isFollowup);
    const canTransfer      = Boolean(!elem.isResolved);
    const canAssignTag     = Boolean(!elem.isResolved && tags.length);
    const canAssignTongue  = Boolean(!elem.isResolved && tongues.length);
    const canAssignHotel   = Boolean(!elem.isResolved && hotels.length > 1);
    const canAssignFlow    = Boolean(!elem.isResolved && flows.length);

    const canAssignAccount = Boolean(canEdit && hasAccount);
    const canBlock         = Boolean(canEdit && elem.contactID);
    const canSummarize     = Boolean(assistants.length);


    // Do the Render
    if (!conversationHash || !elem.id) {
        return <React.Fragment />;
    }
    return <ActionItem icon="more" message="GENERAL_ACTIONS">
        <ActionOption
            isHidden={!canEdit || !providers.length}
            icon="create"
            message="CONVERSATIONS_CREATE_TITLE"
            onClick={() => setOpenDialog("create")}
        />
        <ActionOption
            isHidden={!canMarkUnread}
            icon="unread"
            message="CONVERSATIONS_MARK_UNREAD"
            onClick={() => handleUnread()}
        />
        <ActionOption
            isHidden={!canTransfer}
            direction="left"
            icon="user"
            message="CONVERSATIONS_TRANSFER_TITLE"
            onClick={() => setOpenDialog("transfer")}
        />

        <ActionOption
            isHidden={!canAssignTag}
            direction="left"
            icon="tag"
            message="CONVERSATIONS_ASSIGN_TAG"
        >
            {tagList.map(({ key, value }) => <MenuItem
                key={key}
                value={key}
                message={value}
                icon={!key ? "tag" : (Object.keys(elem.tags).includes(String(key)) ? "checkedbox" : "checkbox")}
                onClick={() => handleTag(key)}
                dontClose={key > 0}
            />)}
        </ActionOption>

        <ActionOption
            isHidden={!canAssignTongue}
            direction="left"
            icon="language"
            message="CONVERSATIONS_ASSIGN_LANGUAGE"
        >
            {tongues.map(({ key, value }) => <MenuItem
                key={key}
                value={key}
                message={value}
                icon={key === elem.tongueID ? "checkedbox" : "checkbox"}
                onClick={() => handleTongue(key)}
            />)}
        </ActionOption>

        <ActionOption
            isHidden={!canAssignHotel}
            direction="left"
            icon="hotel"
            message="CONVERSATIONS_ASSIGN_HOTEL"
        >
            {hotelList.map(({ key, value }) => <MenuItem
                key={key}
                value={key}
                message={value}
                icon={!key ? "none" : (key === elem.hotelID ? "checkedbox" : "checkbox")}
                onClick={() => handleHotel(key)}
            />)}
        </ActionOption>

        <ActionOption
            isHidden={!canAssignFlow}
            direction="left"
            icon="flow"
            message="CONVERSATIONS_ASSIGN_FLOW"
        >
            {flows.map(({ key, value }) => <MenuItem
                key={key}
                value={key}
                message={value}
                icon={key === elem.flowID ? "checkedbox" : "checkbox"}
                onClick={() => handleFlow(key)}
            />)}
        </ActionOption>

        <ActionOption
            isHidden={!canAssignAccount}
            icon="account"
            message="ACCOUNTS_ASSIGN_ACCOUNT_TITLE"
            onClick={() => setOpenDialog("account")}
        />
        <ActionOption
            isHidden={!canBlock}
            icon="block"
            message={contact.isBlocked ? "CONTACTS_UNBLOCK_TITLE" : "CONTACTS_BLOCK_TITLE"}
            onClick={() => setOpenDialog("block")}
        />

        <ActionOption
            isHidden={!canSummarize}
            icon="summary"
            message="CONVERSATIONS_SUMMARIZE_TITLE"
            onClick={() => setOpenDialog("summarize")}
        />
        <ActionOption
            isHidden={!canDelete}
            icon="delete"
            message="CONVERSATIONS_DELETE_TITLE"
            onClick={() => setOpenDialog("delete")}
        />
    </ActionItem>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationActions.propTypes = {
    isUnreadRef   : PropTypes.object.isRequired,
    setOpenDialog : PropTypes.func.isRequired,
};

export default ConversationActions;
