import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Hooks                from "Utils/Hooks";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Values = Styled.div`
    flex-grow: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--main-gap);
    flex-direction: column;
    justify-content: space-around;
`;

const Value = Styled.div.attrs(({ withLinks }) => ({ withLinks }))`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    gap: 16px;
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);

    ${(props) => props.withLinks && `
        transition: all 0.2s;
        cursor: pointer;

        :hover {
            background-color: var(--lightest-gray);
        }
    `}
`;

const Header = Styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = Styled.h3`
    margin: 0;
    text-align: center;
    font-size: 13px;
`;

const Subtitle = Styled.h4`
    margin: 0;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
`;

const Content = Styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

const Iconography = Styled(Icon)`
    color: var(--primary-color);
    font-size: 32px;
`;

const Amount = Styled.div`
    font-size: 28px;
`;

const Unread = Styled.div`
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 4px 8px;
    font-size: 16px;
    color: white;
    background-color: rgb(255, 0, 51);
    border-radius: var(--border-radius);
`;



/**
 * The Reports Values
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ReportsValues(props) {
    const { title, labels, icons, dataset, extras, urls, withLinks } = props;

    const navigate = Hooks.useGoto();


    // Handles the Click
    const handleClick = (index) => {
        if (withLinks) {
            navigate("CONVERSATIONS", urls[index]);
        }
    };


    // Do the Render
    return <Values>
        {labels.map((label, index) => {
            const unread = extras[index];
            return <Value
                key={label}
                withLinks={withLinks}
                onClick={() => handleClick(index)}
            >
                <Header>
                    <Title>{NLS.get(title)}</Title>
                    <Subtitle>{NLS.get(label)}</Subtitle>
                </Header>
                <Content>
                    <Iconography icon={icons[index]} />
                    <Amount>{dataset[index] || 0}</Amount>
                    {!!unread && <Unread>{unread}</Unread>}
                </Content>
            </Value>;
        })}
    </Values>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ReportsValues.propTypes = {
    title     : PropTypes.string.isRequired,
    labels    : PropTypes.array.isRequired,
    icons     : PropTypes.array.isRequired,
    dataset   : PropTypes.array.isRequired,
    extras    : PropTypes.array.isRequired,
    urls      : PropTypes.array.isRequired,
    withLinks : PropTypes.bool.isRequired,
};

export default ReportsValues;
