import React                from "react";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useList              from "Dashboard/Hooks/List";
import Commons              from "Utils/Commons";

// Components
import LineChart            from "Components/Utils/Common/LineChart";

// Dashboard
import CircularLoader       from "Dashboard/Components/Loader/CircularLoader";
import FilterList           from "Dashboard/Components/Filter/FilterList";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";



/**
 * The Campaign Report
 * @returns {React.ReactElement}
 */
function CampaignReport() {
    const { loadFilter } = useList("campaignReport", "CAMPAIGN");

    const data = Store.useState("campaignReport");
    const { loading, forFlow, hasFlow, filters, amounts, labels, channels } = data;


    // Variables
    const colors = !forFlow ? [
        "rgb(245, 206, 107)",
        "rgb(108, 190, 191)",
        "rgb(87, 159, 229)",
        "rgb(19, 90, 222)",
        "rgb(238, 164, 84)",
        "rgb(232, 109, 133)",
    ] : [
        "rgb(245, 206, 107)",
        "rgb(108, 190, 191)",
        "rgb(87, 159, 229)",
        "rgb(232, 109, 133)",
    ];


    // Do the Render
    if (loading) {
        return <CircularLoader />;
    }
    return <>
        <FilterList
            onFilter={loadFilter}
            values={filters}
            hideButton
        >
            <FilterItem
                isHidden={!hasFlow}
                type="select"
                name="reportType"
                label="GENERAL_TYPE"
                options="SELECT_CAMPAIGN_REPORTS"
                hideClear
            />
            <FilterItem
                type="select"
                name="period"
                label="PERIOD_NAME"
                options={Period.getLastSelect()}
                hideClear
            />
            <FilterItem
                type="select"
                name="providerName"
                label="GENERAL_PLATFORM"
                options={Commons.getPlatformSelect(channels)}
            />
            <FilterItem
                type="select"
                name="channelID"
                label="CHANNELS_SINGULAR"
                options={Commons.getChannelSelect(channels)}
            />
        </FilterList>

        <LineChart
            labels={labels}
            amounts={amounts}
            colors={colors}
        />
    </>;
}

export default CampaignReport;
