import React                from "react";

// Components
import EmailQueueList       from "Components/App/Email/EmailQueue/EmailQueueList";
import NotificationLogList  from "Components/App/Log/NotificationLog/NotificationLogList";
import ProviderLogList      from "Components/App/Log/ProviderLog/ProviderLogList";
import AssistantRunLogList  from "Components/App/Log/AssistantRunLog/AssistantRunLogList";
import ConnectorLogList     from "Components/App/Log/ConnectorLog/ConnectorLogList";
import ActionLogList        from "Components/App/Log/ActionLog/ActionLogList";
import QueryLogList         from "Components/App/Log/QueryLog/QueryLogList";
import ErrorLogList         from "Components/App/Log/ErrorLog/ErrorLogList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Log Container
 * @returns {React.ReactElement}
 */
function LogContainer() {
    return <>
        <SecondaryNav message="GENERAL_LOGS" icon="logs">
            <NavigationList>
                <NavigationItem
                    message="EMAIL_QUEUE_NAME"
                    url="EMAIL_QUEUE"
                    icon="email-queue"
                />
                <NavigationItem
                    message="NOTIFICATIONS_NAME"
                    url="LOG_NOTIFICATIONS"
                    icon="notification"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="PROVIDERS_LOG_NAME"
                    url="LOG_PROVIDERS"
                    icon="provider-log"
                />
                <NavigationItem
                    message="ASSISTANTS_RUNS_NAME"
                    url="LOG_ASSISTANTS"
                    icon="assistant-log"
                />
                <NavigationItem
                    message="CONNECTORS_LOG_NAME"
                    url="LOG_CONNECTORS"
                    icon="connector-log"
                />
                <NavigationItem
                    message="ACTIONS_LOG_NAME"
                    url="LOG_ACTIONS"
                    icon="action-log"
                />
                <NavigationItem
                    message="QUERIES_LOG_NAME"
                    url="LOG_QUERIES"
                    icon="query-log"
                />
                <NavigationItem
                    message="ERRORS_LOG_NAME"
                    url="LOG_ERRORS"
                    icon="error-log"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="EMAIL_QUEUE"       component={EmailQueueList}      />
            <AdminRoute url="LOG_NOTIFICATIONS" component={NotificationLogList} />
            <AdminRoute url="LOG_PROVIDERS"     component={ProviderLogList}     />
            <AdminRoute url="LOG_PROVIDER"      component={ProviderLogList}     />
            <AdminRoute url="LOG_ASSISTANTS"    component={AssistantRunLogList} />
            <AdminRoute url="LOG_ASSISTANT"     component={AssistantRunLogList} />
            <AdminRoute url="LOG_CONNECTORS"    component={ConnectorLogList}    />
            <AdminRoute url="LOG_CONNECTOR"     component={ConnectorLogList}    />
            <AdminRoute url="LOG_ACTIONS"       component={ActionLogList}       />
            <AdminRoute url="LOG_QUERIES"       component={QueryLogList}        />
            <AdminRoute url="LOG_QUERY"         component={QueryLogList}        />
            <AdminRoute url="LOG_ERRORS"        component={ErrorLogList}        />
            <AdminRoute url="LOG_ERROR"         component={ErrorLogList}        />
        </Router>
    </>;
}

export default LogContainer;
