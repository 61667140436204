import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Responsive           from "Dashboard/Core/Responsive";
import Store                from "Dashboard/Core/Store";

// Components
import HelpMenu             from "./HelpMenu";

// Dashboard
import MenuTitle            from "Dashboard/Components/Menu/MenuTitle";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";
import MenuLine             from "Dashboard/Components/Menu/MenuLine";



/**
 * The Credential Menu
 * @returns {React.ReactElement[]}
 */
function CredentialMenu() {
    const navigate = Navigate.useGoto();

    const {
        isAnyAdmin, currentUserID, currOrganization,
        hasOrganizations, organizations, isDark,
    } = Store.useState("auth");

    const { showChatSupport } = Store.useState("app");
    const { hasTickets } = Store.useState("permission");

    const { logout } = Store.useAction("auth");
    const { accessOrganization } = Store.useAction("organization");
    const { changeAppearance } = Store.useAction("profile");
    const { setShowChatSupport } = Store.useAction("app");
    const { hideTooltip } = Store.useAction("core");


    // Handles the Organization Access
    const handleAccess = async (userID) => {
        await accessOrganization(userID);
        Navigate.reload();
    };

    // Handles the User Logout
    const handleLogout = async () => {
        hideTooltip();
        const response = await logout();
        if (response.clientID) {
            navigate("CLIENTS", response.clientID, "USERS");
        } else if (response.partnerID) {
            navigate("PARTNERS", response.partnerID, "USERS");
        }
    };


    // Do the Render
    const helpMenu = HelpMenu();
    const result   = [];

    if (hasOrganizations) {
        result.push(<MenuTitle
            key="organizations"
            message="ORGANIZATIONS_NAME"
        />);
        for (const { key, value } of organizations) {
            result.push(<MenuItem
                key={key}
                icon={Number(key) === currentUserID ? "checkedbox" : "checkbox"}
                message={value}
                onClick={() => handleAccess(key)}
            />);
        }
        result.push(<MenuLine key="line" />);
    } else if (currOrganization && window.innerWidth < Responsive.WIDTH_FOR_MOBILE) {
        result.push(<MenuTitle
            key="organizations"
            message="ORGANIZATIONS_SINGULAR"
        />);
        result.push(<MenuItem
            key="currOrganization"
            icon="checkedbox"
            message={currOrganization}
        />);
        result.push(<MenuLine key="line" />);
    }

    if (window.innerWidth < 500) {
        result.push(<MenuTitle
            key="help"
            message="GENERAL_HELP"
        />);
        result.push(...helpMenu);

        if (!isAnyAdmin && hasTickets) {
            result.push(<MenuItem
                key="conversation"
                icon="conversation"
                message="GENERAL_CHAT"
                onClick={() => setShowChatSupport(!showChatSupport)}
            />);
        }
        result.push(<MenuLine key="line2" />);
    }

    result.push(<MenuItem
        key="user"
        icon="user"
        message="ACCOUNT_MINE"
        url="PROFILE"
    />);
    result.push(<MenuItem
        key="appearance"
        icon="appearance"
        message={isDark ? "ACCOUNT_APPEARANCE_LIGHT" : "ACCOUNT_APPEARANCE_DARK"}
        onClick={() => changeAppearance(isDark ? "light" : "dark")}
    />);
    result.push(<MenuItem
        key="logout"
        icon="logout"
        message="GENERAL_LOGOUT"
        onClick={handleLogout}
    />);

    return result;
}

export default CredentialMenu;
