import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";
import { User }             from "Utils/API";

// Components
import UserHeader           from "./UserHeader";
import UserDetails          from "./UserDetails";
import UserDialogs          from "./UserDialogs";
import ColorPreview         from "Components/Utils/Common/ColorPreview";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The User List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserList(props) {
    const { type } = props;
    const { fetch, load, loadFilter } = useList("user", type);

    const { userID } = Navigate.useParams();
    const clientID   = Hooks.useClientID();
    const partnerID  = Hooks.usePartnerID();
    const navigate   = Hooks.useGoto();

    const { loading, canEdit, list, total, filters, sort, elem } = Store.useState("user");

    const { openDetails } = Store.useAction("core");
    const { isAnyAdmin } = Store.useState("auth");
    const { loginAs } = Store.useAction("auth");

    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Add the Hooks for the Details
    Hooks.useItemDetails("user", userID);

    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isView) {
            endAction();
            if (elemID !== userID) {
                navigate("USERS", elemID);
            } else {
                openDetails();
            }
        } else if (action.isLogin) {
            endAction();
            const credentialID = Utils.getValue(list, "userID", elemID, "credentialID");
            loginAs(credentialID);
        } else if (action.isExport) {
            setExporting(true);
            endAction();
            const source = User.export({ ...filters, clientID, partnerID });
            await Utils.download(source, "users.xlsx");
            setExporting(false);
        } else {
            startAction(action, elemID);
        }
    };

    // Returns true if the Resend Invite is hidden
    const hideResend = (elemID) => {
        const isPending = Utils.getValue(list, "userID", elemID, "isPending");
        return !canEdit || !isPending;
    };

    // Returns true if the Login As is hidden
    const hideLoginAs = (elemID) => {
        const isPending = Utils.getValue(list, "userID", elemID, "isPending");
        return !isAnyAdmin || !canEdit || isPending;
    };



    // Variables
    const forPartner     = type === "PARTNER";
    const withNavigation = type === "CLIENT" || forPartner;
    const withDetails    = !!elem.id;


    // Do the Render
    return <>
        <Main withNavigation={withNavigation} withDetails={withDetails}>
            <UserHeader
                startAction={handleAction}
                forPartner={forPartner}
                isExporting={exporting}
            />

            <Content>
                <Table
                    isLoading={loading}
                    fetch={load}
                    sort={sort}
                    none="USERS_NONE_AVAILABLE"
                >
                    <TableHead>
                        <TableHeader field="firstName"            message="GENERAL_NAME"                isTitle isFlex smallSpace />
                        <TableHeader field="email"                message="GENERAL_EMAIL"               />
                        <TableHeader field="access"               message="GENERAL_ACCESS"              maxWidth="90" />
                        <TableHeader field="status"               message="GENERAL_STATUS"              maxWidth="80" />
                        <TableHeader field="qualificationPercent" message="CONVERSATIONS_QUALIFICATION" maxWidth="100" align="center" />
                    </TableHead>
                    <TableBody>
                        {list.map((elem) => <TableRow
                            key={elem.userID}
                            elemID={elem.userID}
                            isSelected={elem.userID === userID}
                        >
                            <TableCell>
                                <ColorPreview color={elem.color} />
                                {elem.displayName}
                            </TableCell>
                            <TableCell message={elem.email}      />
                            <TableCell message={elem.accessName} />
                            <TableCell message={elem.statusName} textColor={elem.statusColor} />
                            <TableCell
                                className={elem.qualificationColor}
                                message={elem.qualificationPercent}
                                tooltip={elem.qualificationTooltip}
                            />
                        </TableRow>)}
                    </TableBody>
                    <TablePaging total={total} />
                    <TableActionList onAction={handleAction} canEdit={canEdit}>
                        <TableAction action="VIEW"   message="USERS_VIEW_TITLE"   />
                        <TableAction action="EDIT"   message="USERS_EDIT_TITLE"   />
                        <TableAction action="RESEND" message="USERS_RESEND_TITLE" hide={hideResend} />
                        <TableAction action="TEAM"   message="TEAMS_USER_TITLE"   isHidden={forPartner} />
                        <TableAction action="LOGIN"  message="GENERAL_LOGIN_AS"   hide={hideLoginAs} />
                        <TableAction action="DELETE" message="USERS_DELETE_TITLE" />
                    </TableActionList>
                </Table>
            </Content>
        </Main>

        <UserDetails startAction={startAction} />

        <UserDialogs
            action={action}
            elemID={elemID}
            endAction={endAction}
            fetch={fetch}
            loadFilter={loadFilter}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default UserList;
