import React                from "react";
import Styled               from "styled-components";

// Components
import SettingsForm         from "./Settings/SettingsForm";
import SettingsAppearance   from "./Settings/SettingsAppearance";
import SettingsConnections  from "./Settings/SettingsConnections";
import SettingsDelete       from "./Settings/SettingsDelete";
import SettingsAvatar       from "./Settings/SettingsAvatar";
import NotificationsButton  from "./Notifications/NotificationsButton";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import Content              from "Dashboard/Components/Core/Content";



// Styles
const Container = Styled(Content)`
    display: flex;
    padding: 0;
    margin-bottom: 24px;

    @media screen and (max-width: 700px) {
        flex-direction: column-reverse;
    }
`;

const Section = Styled.section`
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    gap: 64px;
    padding: 0 24px 0 32px;
    max-width: 500px;

    @media screen and (max-width: 700px) {
        max-width: none;
    }
`;



/**
 * The Settings Page
 * @returns {React.ReactElement}
 */
function SettingsPage() {
    return <>
        <Header message="GENERAL_SETTINGS" icon="settings" />
        <Container>
            <Section>
                <SettingsForm />
                <NotificationsButton />
                <SettingsAppearance />
                <SettingsConnections />
                <SettingsDelete />
            </Section>
            <SettingsAvatar />
        </Container>
    </>;
}

export default SettingsPage;
