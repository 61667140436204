import React                from "react";
import PropTypes            from "prop-types";
import Hooks                from "Utils/Hooks";

// Dashboard
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The Color Preview
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ColorPreview(props) {
    const { color } = props;


    // Variables
    const bgColor = Hooks.useBackgroundColor(color);

    // Do the Render
    return <ColorCircle color={bgColor} />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ColorPreview.propTypes = {
    color : PropTypes.string,
};

export default ColorPreview;
