import React                from "react";
import PropTypes            from "prop-types";
import Utils                from "Dashboard/Utils/Utils";

// Components
import VariableMenu         from "Components/Utils/Menus/VariableMenu";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import IconLink             from "Dashboard/Components/Link/IconLink";
import EmojiPopup           from "Dashboard/Components/Form/EmojiPopup";




/**
 * The Text Field
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TextField(props) {
    const {
        passedRef, className, isHidden,
        name, label, value, helperText, error, maxLength, withEmoji,
        withVariable, variables, onChange, isRequired, isDisabled, children,
    } = props;


    // The References
    const fieldRef    = React.useRef();
    const inputRef    = passedRef || fieldRef;
    const emojiRef    = React.useRef(null);
    const variableRef = React.useRef(null);

    // The Current State
    const [ menu, setMenu ] = React.useState("");


    // Handles the Change
    const handleChange = (name, value) => {
        if (maxLength && value.length > maxLength) {
            onChange(name, value.substring(0, maxLength));
        } else {
            onChange(name, value);
        }
    };

    // Handles the Emoji Click
    const handleEmojiClick = (emoji) => {
        const text = Utils.insertText(inputRef, value, emoji);
        onChange(name, text);
        setMenu("");
        inputRef.current.focus();
    };

    // Handles the Variable Click
    const handleVariableClick = (text) => {
        onChange(name, text);
        setMenu("");
    };


    // Do the Render
    const showVariables = Boolean(withVariable && variables && variables.length > 0);

    if (isHidden) {
        return <React.Fragment />;
    }
    return <>
        <InputField
            passedRef={inputRef}
            className={className}
            name={name}
            label={label}
            value={value}
            helperText={helperText}
            error={error}
            onChange={handleChange}
            isRequired={isRequired}
            isDisabled={isDisabled}
            maxLength={maxLength}
        >
            {withEmoji && <IconLink
                passedRef={emojiRef}
                variant="black"
                icon="emoji"
                tooltip="EMOJI_NAME"
                onClick={() => setMenu("emoji")}
                isDisabled={isDisabled}
                isSmall
            />}
            {withVariable && <IconLink
                passedRef={variableRef}
                variant="black"
                icon="variable"
                tooltip="GENERAL_VARIABLES"
                onClick={() => setMenu("variable")}
                isDisabled={isDisabled}
                isSmall
            />}
            {children}
        </InputField>

        {withEmoji && <EmojiPopup
            open={menu === "emoji"}
            targetRef={emojiRef}
            direction="bottom left"
            gap={4}
            onClick={handleEmojiClick}
            onClose={() => setMenu("")}
        />}
        {showVariables && <VariableMenu
            open={menu === "variable"}
            targetRef={variableRef}
            inputRef={inputRef}
            message={value}
            list={variables}
            direction="bottom left"
            onClick={handleVariableClick}
            onClose={() => setMenu("")}
        />}
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TextField.propTypes = {
    passedRef    : PropTypes.any,
    isHidden     : PropTypes.bool,
    className    : PropTypes.string,
    name         : PropTypes.string.isRequired,
    label        : PropTypes.string.isRequired,
    value        : PropTypes.string.isRequired,
    helperText   : PropTypes.string,
    error        : PropTypes.oneOfType([ PropTypes.string, PropTypes.array ]),
    maxLength    : PropTypes.number,
    withEmoji    : PropTypes.bool,
    withVariable : PropTypes.bool,
    variables    : PropTypes.array,
    onChange     : PropTypes.func.isRequired,
    isRequired   : PropTypes.bool,
    isDisabled   : PropTypes.bool,
    children     : PropTypes.any,
};

export default TextField;
