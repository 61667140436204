import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import Hooks                from "Utils/Hooks";

// Components
import TicketView           from "./TicketView";
import TicketEdit           from "./TicketEdit";
import TicketAssign         from "./TicketAssign";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Filter               from "Dashboard/Components/Filter/Filter";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import PromptDialog         from "Dashboard/Components/Dialogs/PromptDialog";



/**
 * The Ticket List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TicketList(props) {
    const { type } = props;
    const { fetch, load, loadTab, loadFilter } = useList("ticket", type, false);

    const clientID  = Hooks.useClientID();
    const partnerID = Hooks.usePartnerID();
    const navigate  = Hooks.useGoto();
    const { ticketTab, ticketID } = Navigate.useParams();

    const showOrganization = type === "ADMIN";

    const data = Store.useState("ticket");
    const { loading, canEdit, canDelete, canAssign, list, total, waitlists, filters, sort } = data;

    const {
        deleteTicket,
        openTicket, waitTicket, closeTicket,
        muteTicket, unmuteTicket,
    } = Store.useAction("ticket");


    // The References
    const tabRef = React.useRef("");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Show the Ticket
    React.useEffect(() => {
        if (Navigate.isUrl("CREATE", ticketTab)) {
            startAction("CREATE");
        } else if (Number.isInteger(ticketTab)) {
            ticketNavigate("OPEN", ticketTab);
        } else {
            if (tabRef.current !== ticketTab) {
                loadTab(urlToStatus(ticketTab));
                tabRef.current = ticketTab;
            } else if (!tabRef.current && !ticketTab) {
                loadTab("Open");
                tabRef.current = NLS.url("OPEN");
            }
            if (ticketID && Number.isInteger(ticketID)) {
                startAction("VIEW", ticketID);
            }
        }
    }, [ ticketTab, ticketID ]);

    // Navigates to the Ticket
    const ticketNavigate = (...params) => {
        if (isAdmin) {
            navigate("SUPPORT", "TICKETS", ...params);
        } else {
            navigate("TICKETS", ...params);
        }
    };

    // Converts a Url to a Status
    const urlToStatus = (url) => {
        if (Navigate.isUrl("OPEN", url)) {
            return "Open";
        }
        if (Navigate.isUrl("WAITING", url)) {
            return "Waiting";
        }
        if (Navigate.isUrl("CLOSED", url)) {
            return "Closed";
        }
        return "Open";
    };


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isView) {
            ticketNavigate(tabRef.current || "OPEN", elemID);
        } else if (action.isTab) {
            const url = String(elemID).toUpperCase();
            ticketNavigate(url);
        } else if (action.isClient) {
            const clientID = Utils.getValue(list, "ticketID", elemID, "clientID");
            navigate("CLIENTS", clientID, "TICKETS");
        } else if (action.isPartner) {
            const partnerID = Utils.getValue(list, "ticketID", elemID, "partnerID");
            navigate("PARTNERS", partnerID, "TICKETS");
        } else if (action.isMute) {
            handleMute(elemID);
        } else if (action.isUnmute) {
            handleUnmute(elemID);
        } else {
            startAction(action, elemID);
        }
    };

    // Completes an Action
    const completeAction = (update) => {
        ticketNavigate(tabRef.current);
        endAction();
        if (update) {
            fetch();
        }
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        completeAction(true);
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        const response = await deleteTicket(elemID);
        completeAction(response.success);
    };

    // Handles the Open Submit
    const handleOpen = async () => {
        const response = await openTicket(elemID);
        completeAction(response.success);
    };

    // Handles the Wait Submit
    const handleWait = async (ticketWaitListID) => {
        const response = await waitTicket(elemID, ticketWaitListID);
        completeAction(response.success);
    };

    // Handles the Close Submit
    const handleClose = async () => {
        const response = await closeTicket(elemID);
        completeAction(response.success);
    };

    // Handles the Mute Submit
    const handleMute = async (elemID) => {
        const response = await muteTicket(elemID);
        completeAction(response.success);
    };

    // Handles the Unmute Submit
    const handleUnmute = async (elemID) => {
        const response = await unmuteTicket(elemID);
        completeAction(response.success);
    };


    // Returns true if it the View Client is hidden
    const hideViewClient = (elemID) => {
        const clientID = Utils.getValue(list, "ticketID", elemID, "clientID");
        return !showOrganization || !clientID;
    };

    // Returns true if the View Partner is hidden
    const hideViewPartner = (elemID) => {
        const partnerID = Utils.getValue(list, "ticketID", elemID, "partnerID");
        return !showOrganization || !partnerID;
    };

    // Returns true if the Edit is hidden
    const hideEdit = (elemID) => {
        return !Utils.getValue(list, "ticketID", elemID, "canEdit");
    };

    // Returns true if the Assign is hidden
    const hideAssign = (elemID) => {
        return !canAssign || hideEdit(elemID);
    };

    // Returns true if the Open is hidden
    const hideOpen = (elemID) => {
        return !Utils.getValue(list, "ticketID", elemID, "canOpen");
    };

    // Returns true if the Wait is hidden
    const hideWait = (elemID) => {
        return !Utils.getValue(list, "ticketID", elemID, "canWait");
    };

    // Returns true if the Close is hidden
    const hideClose = (elemID) => {
        return !Utils.getValue(list, "ticketID", elemID, "canClose");
    };

    // Returns true if the Mute is hidden
    const hideMute = (elemID) => {
        return !Utils.getValue(list, "ticketID", elemID, "isMute");
    };

    // Returns true if the unmute is hidden
    const hideUnmute = (elemID) => {
        return Utils.getValue(list, "ticketID", elemID, "isMute");
    };


    // Variables
    const isAdmin        = type === "ADMIN";
    const withNavigation = type === "CLIENT" || type === "PARTNER" || isAdmin;
    const hideWaitlist   = sort.filter !== "Waiting";
    const elem           = Utils.getValue(list, "ticketID", elemID);
    const elemName       = elem.subject;


    // Do the Render
    return <Main withNavigation={withNavigation}>
        <Header message="TICKETS_NAME" icon="ticket">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <TabList
                variant="lined"
                selected={sort.filter}
                onAction={handleAction}
            >
                <TabItem
                    message="TICKETS_OPEN_TAB"
                    icon="ticket-open"
                    value="Open"
                />
                <TabItem
                    message="TICKETS_WAITING_TAB"
                    icon="waitlist"
                    value="Waiting"
                />
                <TabItem
                    message="TICKETS_CLOSE_TAB"
                    icon="ticket-closed"
                    value="Closed"
                />
            </TabList>

            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            />
            <Table
                isLoading={loading}
                fetch={load}
                sort={sort}
                none="TICKETS_NONE_AVAILABLE"
            >
                <TableHead>
                    <TableHeader field="ticketID"           message="GENERAL_ID"                maxWidth="50" />
                    <TableHeader field="organization"       message="TICKETS_ORGANIZATION"      isFlex smallSpace grow="2" isHidden={!showOrganization} />
                    <TableHeader field="subject"            message="GENERAL_SUBJECT"           isTitle grow="2" bigMobile />
                    <TableHeader field="ticketTypeName"     message="TICKET_TYPES_SINGULAR"     />
                    <TableHeader field="ticketWaitlistName" message="TICKET_WAITLISTS_SINGULAR" isHidden={hideWaitlist} />
                    <TableHeader field="adminUserID"        message="USERS_SINGULAR"            />
                    <TableHeader field="adminCredentialID"  message="TICKETS_ASSIGNED"          />
                    <TableHeader field="lastReplyTime"      message="TICKETS_LAST_REPLY"        minWidth="160" maxWidth="160" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.ticketID} elemID={elem.ticketID}>
                        <TableCell message={elem.ticketNumber}       />
                        <TableCell message={elem.organization}       />
                        <TableCell message={elem.subject}            />
                        <TableCell message={elem.ticketTypeName}     />
                        <TableCell message={elem.ticketWaitlistName} />
                        <TableCell message={elem.userName}           />
                        <TableCell message={elem.adminName}          />
                        <TableCell message={elem.lastReplyText}      />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW"    message="TICKETS_VIEW_TITLE"    />
                    <TableAction action="CLIENT"  message="CLIENTS_VIEW_TITLE"    hide={hideViewClient}  />
                    <TableAction action="PARTNER" message="PARTNERS_VIEW_TITLE"   hide={hideViewPartner} />
                    <TableAction action="EDIT"    message="TICKETS_EDIT_TITLE"    hide={hideEdit}        />
                    <TableAction action="ASSIGN"  message="TICKETS_ASSIGN_TITLE"  hide={hideAssign}      />
                    <TableAction action="OPEN"    message="TICKETS_OPEN_TITLE"    hide={hideOpen}        />
                    <TableAction action="WAIT"    message="TICKETS_WAITING_TITLE" hide={hideWait}        />
                    <TableAction action="CLOSE"   message="TICKETS_CLOSE_TITLE"   hide={hideClose}       />
                    <TableAction action="MUTE"    message="TICKETS_MUTE_TITLE"    hide={hideMute}        />
                    <TableAction action="UNMUTE"  message="TICKETS_UNMUTE_TITLE"  hide={hideUnmute}      />
                    <TableAction action="DELETE"  message="TICKETS_DELETE_TITLE"  isHidden={!canDelete}  />
                </TableActionList>
            </Table>
        </Content>

        <TicketView
            open={action.isView}
            type={type}
            elemID={elemID}
            onClose={completeAction}
        />
        <TicketEdit
            open={action.isCE}
            elemID={elemID}
            clientID={clientID}
            partnerID={partnerID}
            onSubmit={handleEdit}
            onClose={completeAction}
        />
        <TicketAssign
            open={action.isAssign}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />

        <ConfirmDialog
            open={action.isOpen}
            icon="status"
            title="TICKETS_OPEN_TITLE"
            message="TICKETS_OPEN_TEXT"
            content={elemName}
            onSubmit={handleOpen}
            onClose={endAction}
        />
        <PromptDialog
            open={action.isWait}
            icon="wait"
            title="TICKETS_WAITING_TITLE"
            message="TICKETS_WAITING_TEXT"
            content={elemName}
            inputType="select"
            inputLabel="TICKET_WAITLISTS_SINGULAR"
            initialValue={elem.ticketWaitListID}
            inputOptions={waitlists}
            onSubmit={handleWait}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isClose}
            icon="status"
            title="TICKETS_CLOSE_TITLE"
            message="TICKETS_CLOSE_TEXT"
            content={elemName}
            onSubmit={handleClose}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="TICKETS_DELETE_TITLE"
            message="TICKETS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TicketList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default TicketList;
