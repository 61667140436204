import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Responsive           from "Dashboard/Core/Responsive";
import useList              from "Dashboard/Hooks/List";
import Utils                from "Dashboard/Utils/Utils";
import Conversations        from "Utils/Conversations";
import Hooks                from "Utils/Hooks";

// Components
import ConversationDetails  from "./ConversationDetails";
import ConversationHeader   from "./Actions/ConversationHeader";
import ListContainer        from "./List/ListContainer";
import ChatContainer        from "./Chat/ChatContainer";
import ChatEmpty            from "./Chat/ChatEmpty";

// Dashboard
import Content              from "Dashboard/Components/Core/Content";



// Styles
const Container = Styled.main.attrs(({ isAdmin, withNavigation, withDetails }) => ({ isAdmin, withNavigation, withDetails }))`
    --header-padding: var(--details-spacing);
    --main-padding: var(--details-spacing);

    --main-navigation: ${(props) => props.withNavigation ? "var(--navigation-width)" : "0px"};
    --main-conversation: calc(var(--conversation-width) + var(--main-padding) * 2);

    display: grid;
    grid-template-columns: var(--main-conversation) 1fr;
    gap: var(--grid-gap);
    width: calc(100vw - var(--sidebar-width) - var(--main-navigation) - var(--main-margin));

    ${(props) => props.withDetails && `
        grid-template-columns: var(--main-conversation) 1fr var(--details-width);
    `}

    @media (max-width: 1500px) {
        ${(props) => props.isAdmin && `
            --main-conversation: 300px;
        `}
    }

    @media (max-width: ${Responsive.WIDTH_FOR_DETAILS}px) {
        ${(props) => !props.isAdmin && `
            --main-conversation: calc(var(--conversation-width) + var(--main-padding) * 2);
        `}
        grid-template-columns: var(--main-conversation) 1fr;
    }

    @media (max-width: ${Responsive.WIDTH_FOR_MENU}px) {
        --main-conversation: calc(var(--conversation-width) + var(--main-padding) * 2);
        gap: 0;
        width: 100vw;

        & > section:first-child {
            border-right: 1px solid var(--border-color-light);
        }
    }

    @media (max-width: ${Responsive.WIDTH_FOR_MOBILE}px) {
        grid-template-columns: 1fr;
        display: block;
        width: 100vw;

        & > section:first-child {
            border-right: none;
        }
    }
`;

const Inside = Styled(Content)`
    padding: 0;
    margin-bottom: var(--main-margin);
    border-radius: var(--main-radius);
    background-color: var(--content-color);
`;



/**
 * The Conversation Page
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ConversationPage(props) {
    const { type } = props;

    const { conversationTab, conversationHash } = Navigate.useParams();
    const navigate = Hooks.useGoto();

    const { isAnyAdmin } = Store.useState("auth");
    const { loading, modified } = Store.useState("conversation");
    const { fetch, loadTab } = useList("conversation", type, false);


    // The References
    const timerRef    = React.useRef(0);
    const tabRef      = React.useRef("");
    const isUnreadRef = React.useRef(0);

    // The Current State
    const [ update, setUpdate ] = React.useState(0);


    // Go to Queue and remove the Auto-update
    React.useEffect(() => {
        if (!conversationTab) {
            navigate("CONVERSATIONS", "QUEUE");
        }
        return () => {
            Utils.clearTimeout(timerRef);
        };
    }, []);

    // Handle the Tab and Update
    React.useEffect(() => {
        if (!conversationTab) {
            navigate("CONVERSATIONS", "QUEUE");
        } else if (!Conversations.isValidStatusUrl(conversationTab)) {
            navigate("CONVERSATIONS", "QUEUE", conversationTab);
        } else if (tabRef.current !== conversationTab) {
            load(conversationTab, true);
            tabRef.current = conversationTab;
        } else {
            load(conversationTab, false);
        }
    }, [ conversationTab, modified, update ]);


    // Loads the data and sets an auto-update
    const load = (conversationTab, isNew) => {
        if (isNew) {
            const status = Conversations.urlToStatus(conversationTab);
            loadTab(status, 20, false);
        } else {
            fetch();
        }

        Utils.setTimeout(timerRef, () => {
            setUpdate(update + 1);
        }, 15 * 1000);
    };


    // Variables
    const isMobile         = Utils.isMobile();
    const withNavigation   = type === "CLIENT";
    const showList         = Boolean(!isMobile || (isMobile && !conversationHash));
    const showConversation = Boolean(!isMobile || (isMobile && conversationHash));
    const showEmpty        = Boolean(showConversation && !conversationHash);
    const showChat         = Boolean(showConversation && conversationHash);


    // Do the Render
    return <Container
        className="conversations"
        isAdmin={isAnyAdmin}
        withNavigation={withNavigation}
        withDetails={showChat}
    >
        {showList && <ListContainer fetch={fetch} />}

        {showConversation && <Inside isLoading={loading}>
            <ConversationHeader isUnreadRef={isUnreadRef} />
            {showEmpty && <ChatEmpty />}
            {showChat  && <ChatContainer isUnreadRef={isUnreadRef} />}
        </Inside>}

        {showChat  && <ConversationDetails />}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ConversationPage.propTypes = {
    type : PropTypes.string.isRequired,
};

export default ConversationPage;
