import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";



/**
 * Returns the Client ID
 * @returns {Number}
 */
function useClientID() {
    const { clientID } = Navigate.useParams();
    const { currentClientID } = Store.useState("auth");
    return clientID || currentClientID || 0;
}

/**
 * Returns the Partner ID
 * @returns {Number}
 */
function usePartnerID() {
    const { partnerID } = Navigate.useParams();
    const { currentPartnerID } = Store.useState("auth");
    return partnerID || currentPartnerID || 0;
}

/**
 * Goes to a Url
 * @returns {Function}
 */
function useGoto() {
    const { clientID, partnerID } = Navigate.useParams();
    const navigate = Navigate.useGoto();

    return (...args) => {
        if (args.length === 1 && args[0].startsWith("/")) {
            navigate(...args);
        } else if (partnerID && clientID) {
            navigate("PARTNERS", partnerID, "CLIENTS", clientID, ...args);
        } else if (partnerID) {
            navigate("PARTNERS", partnerID, ...args);
        } else if (clientID) {
            navigate("CLIENTS", clientID, ...args);
        } else {
            navigate(...args);
        }
    };
}

/**
 * Goes to a Url depending on the type
 * @param {String} type
 * @returns {Function}
 */
function useAdminGoto(type) {
    const navigate       = Navigate.useGoto();
    const commonNavigate = useGoto();

    return (clientID, ...args) => {
        if (type === "ADMIN") {
            navigate("CLIENTS", clientID, ...args);
        } else {
            commonNavigate(...args);
        }
    };
}

/**
 * Goes to a Url in a Log
 * @param {String} type
 * @returns {Function}
 */
function useLogGoto(type) {
    const navigate       = Navigate.useGoto();
    const commonNavigate = useGoto();

    return (...args) => {
        if (type === "ADMIN") {
            navigate("LOGS", ...args);
        } else {
            commonNavigate("SETTINGS", ...args);
        }
    };
}

/**
 * Returns the State to use a Tongue
 * @param {Object[]} tongues
 * @returns {Array}
 */
function useTongue(tongues) {
    return React.useState(tongues.length > 0 ? tongues[0].key : 0);
}

/**
 * Returns a Hook to use the Progress
 * @returns {Array}
 */
function useProgress() {
    const { fetchProgress } = Store.useAction("app");

    const timerRef = React.useRef(null);
    const [ progress, setProgress ] = React.useState(0);

    React.useEffect(() => {
        window.clearTimeout(timerRef.current);
        if (progress) {
            timerRef.current = window.setTimeout(() => {
                fetchProgress().then((response) => {
                    setProgress(response.progress);
                });
            }, 1000);
        }
    }, [ progress ]);

    return [ progress, setProgress ];
}

/**
 * Returns a Hook to have Details for an Item
 * @param {String}    slice
 * @param {Number}    itemID
 * @param {Function=} fetch
 * @returns {Void}
 */
function useItemDetails(slice, itemID, fetch = null) {
    const { elem } = Store.useState(slice);
    const { fetchElem, removeElem } = Store.useAction(slice);
    const { openDetails } = Store.useAction("core");

    // Removes the Item on exit
    React.useEffect(() => {
        return () => {
            removeElem();
        };
    }, []);

    // Handles the Item change
    React.useEffect(() => {
        if (itemID) {
            if (fetch) {
                fetch(itemID);
            } else {
                fetchElem(itemID);
            }
        }
    }, [ itemID ]);

    // Handles the User change
    React.useEffect(() => {
        if (elem.id) {
            openDetails();
        }
    }, [ elem.id ]);
}

/**
 * Returns the Background Color
 * @param {String} color
 * @returns {String}
 */
function useBackgroundColor(color) {
    const { isDark } = Store.useState("auth");

    const finalColor = color || "#f2f2f2";
    const bgColor    = isDark && finalColor === "#f2f2f2" ? "#393939" : finalColor;
    return bgColor;
}




// The public API
export default {
    useClientID,
    usePartnerID,
    useGoto,
    useAdminGoto,
    useLogGoto,
    useTongue,
    useProgress,
    useItemDetails,
    useBackgroundColor,
};
