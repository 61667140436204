import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import AnnouncementEdit     from "./AnnouncementEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Announcement List
 * @returns {React.ReactElement}
 */
function AnnouncementList() {
    const { fetch, load } = useList("announcement");

    const data = Store.useState("announcement");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteAnnouncement } = Store.useAction("announcement");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteAnnouncement(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Do the Render
    const elemName = Utils.getValue(list, "announcementID", elemID, "message");

    return <Main>
        <Header message="ANNOUNCEMENTS_NAME" icon="announcement">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="ANNOUNCEMENTS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="message"  message="GENERAL_MESSAGE"   isTitle />
                    <TableHeader field="fromTime" message="GENERAL_FROM_DATE" maxWidth="160" />
                    <TableHeader field="toTime"   message="GENERAL_TO_DATE"   maxWidth="160" />
                    <TableHeader field="isActive" message="GENERAL_STATUS"    maxWidth="70" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.announcementID} elemID={elem.announcementID}>
                        <TableCell message={elem.message}      />
                        <TableCell message={elem.fromTimeText} />
                        <TableCell message={elem.toTimeText}   />
                        <TableCell message={elem.statusName}   textColor={elem.statusColor} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="ANNOUNCEMENTS_EDIT_TITLE"   />
                    <TableAction action="DELETE" message="ANNOUNCEMENTS_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <AnnouncementEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="ANNOUNCEMENTS_DELETE_TITLE"
            message="ANNOUNCEMENTS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default AnnouncementList;
