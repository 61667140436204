import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Announcement Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AnnouncementEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editAnnouncement } = Store.useAction("announcement");


    // The Initial Data
    const initialData = {
        announcementID : 0,
        message        : "",
        url            : "",
        fromDate       : "",
        fromHour       : "",
        toDate         : "",
        toHour         : "",
        autoCloseMins  : 0,
        isActive       : 0,
        removeDiscards : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("announcement", initialData, editAnnouncement, onSubmit);

    // Load the Data
    const { loading } = useDialog("announcement", open, elemID, null, setElem);



    // Do the Render
    const showResetDiscarded = Boolean(elemID && data.isActive);

    return <EditDialog
        open={open}
        icon="announcement"
        title={elemID ? "ANNOUNCEMENTS_EDIT_TITLE" : "ANNOUNCEMENTS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="message"
            label="GENERAL_MESSAGE"
            value={data.message}
            error={errors.message}
            onChange={handleChange}
            maxLength={200}
            isRequired
        />
        <InputField
            name="url"
            label="GENERAL_URL"
            value={data.url}
            error={errors.url}
            onChange={handleChange}
        />

        <InputField
            type="double"
            error={errors.fromDate}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="fromDate"
                label="GENERAL_FROM_DATE"
                maxValue="2999-12-31"
                value={data.fromDate}
                isRequired
            />
            <InputItem
                type="time"
                name="fromHour"
                label="GENERAL_FROM_HOUR"
                value={data.fromHour}
                isRequired
            />
        </InputField>
        <InputField
            type="double"
            error={errors.toDate}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="toDate"
                label="GENERAL_TO_DATE"
                maxValue="2999-12-31"
                value={data.toDate}
                isRequired
            />
            <InputItem
                type="time"
                name="toHour"
                label="GENERAL_TO_HOUR"
                value={data.toHour}
                isRequired
            />
        </InputField>

        <InputField
            type="number"
            name="autoCloseMins"
            label="ANNOUNCEMENTS_AUTO_CLOSE"
            helperText="ANNOUNCEMENTS_AUTO_CLOSE_TIP"
            value={data.autoCloseMins}
            error={errors.autoCloseMins}
            onChange={handleChange}
        />
        <Columns>
            <InputField
                className={!showResetDiscarded ? "columns-double" : ""}
                type="toggle"
                name="isActive"
                label="ANNOUNCEMENTS_IS_ACTIVE"
                value={!!data.isActive}
                onChange={handleChange}
                withBorder
            />
            <InputField
                isHidden={!showResetDiscarded}
                type="toggle"
                name="removeDiscards"
                label="ANNOUNCEMENTS_RESET_DISCARDED"
                value={!!data.removeDiscards}
                onChange={handleChange}
                withBorder
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AnnouncementEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default AnnouncementEdit;
