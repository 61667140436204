import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { Dashboard }        from "Utils/API";



// The initial State
const initialState = {
    loading : true,
    error   : false,
    edition : 0,
    list    : [],
    filters : {},
    sort    : {
        orderBy  : "resolved",
        orderAsc : 1,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_USERS_LOADING" });
    },

    /**
     * Fetches the Dashboard List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  sort
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, sort = {}) {
        const params = { ...sort };
        if (type === "CLIENT") {
            params.clientID = elemID;
        }

        const data = await Dashboard.getUsers(params);
        data.sort  = sort;

        dispatch({ type : "DASHBOARD_USERS_LIST", data });
        dispatch({ type : "DASHBOARD_FILTER_DATA", data });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    Commons.parseQualifications(elem);
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_USERS_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_USERS_LOADING":
        return {
            ...state,
            loading : true,
        };

    case "DASHBOARD_USERS_LIST":
        return {
            ...state,
            loading : false,
            error   : false,
            list    : Utils.parseList(action.data.list, parseElem),
            filters : action.data.filters,
            sort    : action.data.sort,
        };

    case "DASHBOARD_FILTERED":
        return {
            ...state,
            edition : state.edition + 1,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
