import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import Hooks                from "Utils/Hooks";



// Styles
const Item = Styled.div.attrs(({ bgColor, textColor }) => ({ bgColor, textColor }))`
    padding: 3px 6px;
    font-size: 12px;
    color: ${(props) => props.textColor};
    background-color: ${(props) => props.bgColor};
    border-radius: var(--border-radius-small);
`;



/**
 * The Color Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ColorItem(props) {
    const { color, message } = props;

    // Variables
    const bgColor   = Hooks.useBackgroundColor(color);
    const textColor = Utils.getContrastColor(bgColor);


    // Do the Render
    return <Item
        bgColor={bgColor}
        textColor={textColor}
    >{NLS.get(message)}</Item>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ColorItem.propTypes = {
    color   : PropTypes.string,
    message : PropTypes.string,
};

export default ColorItem;
