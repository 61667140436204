import React                from "react";
import OneSignal            from "react-onesignal";
import Store                from "Dashboard/Core/Store";

// Dashboard
import SnackBar             from "Dashboard/Components/Core/SnackBar";



/**
 * The Notifications Bar
 * @returns {React.ReactElement}
 */
function NotificationsBar() {
    const { loggedAsUser, credentialID, credential } = Store.useState("auth");

    const { notificationsSupported, showNotificationBar } = Store.useState("notification");
    const {
        setNotificationsSupported, setShowNotificationBar,
        setNotificationsGranted, setNotificationsDenied,
        addDevice, removeDevice, stopAsking,
    } = Store.useAction("notification");


    // Initializes One Signal
    React.useEffect(() => {
        const isLocal = process.env.NODE_ENV === "development";
        if (credentialID && !isLocal) {
            if (!notificationsSupported) {
                initOneSignal();
            } else {
                setUser();
            }
        }
    }, [ credentialID ]);

    // Initializes One Signal
    const initOneSignal = async () => {
        await OneSignal.init({
            appId : process.env.REACT_APP_ONESIGNAL_ID,
            safari_web_id : process.env.REACT_APP_ONESIGNAL_SAFARI,
            serviceWorkerParam : { scope : "/push/onesignal/" },
            allowLocalhostAsSecureOrigin : true,
        });

        const isSupported = OneSignal.Notifications.isPushSupported();
        if (!isSupported) {
            return;
        }
        setNotificationsSupported(true);

        OneSignal.Notifications.addEventListener("permissionChange", (isGranted) => {
            const playerID = OneSignal.User.PushSubscription.id;
            if (playerID) {
                handleSubscription(playerID, isGranted);
            }
            handlePermission(isGranted);
        });

        OneSignal.User.PushSubscription.addEventListener("change", ({ previous, current }) => {
            if (previous.id !== current.id && current.id) {
                handleSubscription(current.id, current.optedIn);
            }
        });

        setUser();
    };

    // Sets the User
    const setUser = async () => {
        await OneSignal.login(String(credentialID));

        const permissionNative = OneSignal.Notifications.permissionNative;
        setNotificationsGranted(permissionNative === "granted");
        setNotificationsDenied(permissionNative === "denied");

        handlePermission(permissionNative === "granted");
    };


    // Handle the Subscription Change
    const handleSubscription = (playerID, isGranted) => {
        if (isGranted) {
            addDevice(credentialID, playerID);
        } else {
            removeDevice(credentialID, playerID);
        }
        setNotificationsGranted(isGranted);
        setNotificationsDenied(!isGranted);
    };

    // Handles the Permissions Change
    const handlePermission = (isGranted) => {
        setShowNotificationBar(!loggedAsUser && credential.askNotifications && !isGranted);
    };

    // Handles the Yes Click
    const handleYesClick = async () => {
        OneSignal.Notifications.requestPermission();
        stopAsking(credentialID);
        setShowNotificationBar(false);
    };

    // Handles the Close
    const handleClose= async () => {
        stopAsking(credentialID);
        setShowNotificationBar(false);
    };


    // Do the Render
    return <SnackBar
        isHidden={!showNotificationBar}
        message="NOTIFICATIONS_BAR_TEXT"
        buttonText="GENERAL_YES"
        onAccept={handleYesClick}
        onClose={handleClose}
    />;
}

export default NotificationsBar;
