import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Responsive           from "Dashboard/Core/Responsive";

// Components
import DocumentList         from "./DocumentList";
import DocumentActions      from "./DocumentActions";

// Dashboard
import Header               from "Dashboard/Components/Header/Header";
import InputField           from "Dashboard/Components/Form/InputField";
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";



// Styles
const Container = Styled.aside`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: var(--main-height, var(--full-height));
    border-radius: var(--main-radius);
    background-color: var(--content-color);

    @media (max-width: ${Responsive.WIDTH_FOR_MENU}px) {
        border-right: 1px solid var(--border-color-light);
    }
`;

const Title = Styled(Header)`
    flex-shrink: 0;
    div {
        display: none;
    }

    @media (max-width: ${Responsive.WIDTH_FOR_MOBILE}px) {
        div {
            display: flex;
        }
    }
`;

const Search = Styled.header`
    padding: var(--main-padding);
    padding-top: 0;
    flex-shrink: 0;
`;

const List = Styled.section`
    box-sizing: border-box;
    padding: var(--main-padding);
    padding-top: 0;
    overflow: auto;
    flex-grow: 2;
`;

const None = Styled(NoneAvailable)`
    text-align: center;
`;



/**
 * The Document Aside
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DocumentAside(props) {
    const { roots, updateAction, onDelete, onSelect } = props;


    // The Current State
    const [ search, setSearch ] = React.useState("");


    // Filters the Documents
    const filterDocuments = (document, search, isVisible) => {
        document.isVisible = false;
        if (isVisible) {
            document.isVisible = isVisible;
        } else if (!search.length) {
            document.isVisible = true;
        } else if (String(document.name).toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            document.isVisible = true;
        }

        let childVisible = document.isVisible;
        for (const child of document.children) {
            const isVisible = filterDocuments(child, search, document.isVisible);
            if (isVisible) {
                childVisible = true;
            }
        }
        if (!document.isVisible && childVisible) {
            document.isVisible = true;
        }
        return document.isVisible;
    };

    // Filter the Documents
    const documents = React.useMemo(() => {
        for (const root of roots) {
            root.isVisible = filterDocuments(root, search);
        }
        return roots;
    }, [ search, JSON.stringify(roots) ]);


    // Variables
    const showSearch   = documents.length > 0;
    const hasDocuments = documents.filter((elem) => elem.isVisible).length > 0;


    // Do the Render
    return <Container>
        <Title message="DOCUMENTS_NAME" icon="document">
            <DocumentActions
                updateAction={updateAction}
                onDelete={onDelete}
            />
        </Title>

        {showSearch && <Search>
            <InputField
                icon="search"
                placeholder="GENERAL_SEARCH"
                value={search}
                onChange={(name, value) => setSearch(value)}
                hasClear
                isSmall
            />
        </Search>}

        <List>
            {!hasDocuments && <None
                message="DOCUMENTS_NONE_AVAILABLE"
            />}
            {hasDocuments && <DocumentList
                documents={documents}
                onSelect={onSelect}
            />}
        </List>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DocumentAside.propTypes = {
    roots        : PropTypes.array.isRequired,
    updateAction : PropTypes.func.isRequired,
    onDelete     : PropTypes.func.isRequired,
    onSelect     : PropTypes.func.isRequired,
};

export default DocumentAside;
