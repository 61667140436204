import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Document }         from "Utils/API";



// The initial State
const initialState = {
    loading   : true,
    error     : false,
    edition   : 0,
    canCreate : false,
    canEdit   : false,
    canImport : false,
    canExport : false,
    list      : [],
    roots     : [],
    nodes     : {},
    parents   : [],
    collapsed : new Set(),
    elem      : {
        content : "",
    },
    filters   : {
        language     : "",
        documentType : "",
    },
    statuses  : [],
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DOCUMENT_LOADING" });
    },

    /**
     * Fetches the Document List
     * @param {Function} dispatch
     * @param {Object=}  filters
     * @returns {Promise}
     */
    async fetchList(dispatch, filters = {}) {
        const data = await Document.getAll(filters);
        data.filters = filters;
        dispatch({ type : "DOCUMENT_LIST", data });
    },

    /**
     * Fetches a single Document
     * @param {Function} dispatch
     * @param {Number}   documentID
     * @returns {Promise}
     */
    async fetchElem(dispatch, documentID) {
        const data = await Document.getOne({ documentID });
        dispatch({ type : "DOCUMENT_ELEM", data });
    },

    /**
     * Fetches a single Document
     * @param {Function} dispatch
     * @param {String}   language
     * @param {String}   documentType
     * @param {String}   slug
     * @returns {Promise}
     */
    async fetchDocument(dispatch, language, documentType, slug) {
        const data = await Document.getOne({ language, documentType, slug });
        dispatch({ type : "DOCUMENT_ELEM", data });
    },

    /**
     * Edits a Document
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editDocument(dispatch, data) {
        return Document.edit(data);
    },

    /**
     * Saves a Document
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    saveDocument(dispatch, data) {
        return Document.save(data);
    },

    /**
     * Deletes a Document
     * @param {Function} dispatch
     * @param {Number}   documentID
     * @returns {Promise}
     */
    deleteDocument(dispatch, documentID) {
        return Document.delete({ documentID });
    },

    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @param {Number}   documentID
     * @returns {Void}
     */
    toggleCollapsed(dispatch, documentID) {
        dispatch({ type : "DOCUMENT_COLLAPSED", documentID });
    },
};



/**
 * Creates a tree of elements
 * @param {Object[]} list
 * @returns {{nodes: Object, roots: Object[], parents: Object[]}}
 */
function parseTree(list) {
    const nodes   = {};
    const roots   = [];
    const parents = [];

    Utils.parseList(list, ({ id, name, slug, parentID }) => {
        nodes[id] = { id, name, slug, children : [], isVisible : true };
        if (!parentID) {
            roots.push(nodes[id]);
        }
    });

    Utils.parseList(list, ({ id, parentID }) => {
        if (parentID && nodes[parentID]) {
            nodes[parentID].children.push(nodes[id]);
        }
    });

    parseList(parents, roots);
    return { nodes, roots, parents };
}

/**
 * Creates a list of elements
 * @param {Object[]} list
 * @param {Object[]} nodes
 * @param {Number=}  level
 * @returns {Void}
 */
function parseList(list, nodes, level = 0) {
    for (const { id, name, children } of nodes) {
        const value = "--".repeat(level) + " " + name;
        list.push({ key : id, value });
        if (children.length) {
            parseList(list, children, level + 1);
        }
    }
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DOCUMENT_LIST", "DOCUMENT_ELEM")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DOCUMENT_LOADING":
        return {
            ...state,
            loading   : true,
        };

    case "DOCUMENT_LIST": {
        const { nodes, roots, parents } = parseTree(action.data.list);
        return {
            ...state, nodes, roots, parents,
            loading   : false,
            error     : false,
            canCreate : action.data.canCreate,
            canEdit   : action.data.canEdit,
            canImport : action.data.canImport,
            canExport : action.data.canExport,
            list      : action.data.list,
            filters   : action.data.filters,
            statuses  : action.data.statuses,
        };
    }

    case "DOCUMENT_ELEM":
        return {
            ...state,
            error     : false,
            edition   : state.edition + 1,
            canEdit   : action.data.canEdit,
            elem      : action.data.elem,
        };

    case "DOCUMENT_COLLAPSED": {
        const collapsed = new Set(state.collapsed);
        if (collapsed.has(action.documentID)) {
            collapsed.delete(action.documentID);
        } else {
            collapsed.add(action.documentID);
        }
        return { ...state, collapsed };
    }

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
