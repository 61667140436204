import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import FieldsItems          from "./FieldsItems";

// Dashboard
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";



// Styles
const Ul = Styled.ul`
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 12px;
`;

const Li = Styled.li.attrs(({ isSelected, percent }) => ({ isSelected, percent }))`
    position: relative;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    margin-top: 15px;

    &:nth-child(-n + 2)::before {
        content: "";
        position: absolute;
        top: -10px;
        right: 0;
        height: 2px;
        background-image: ${(props) => `linear-gradient(to right, var(--primary-color) ${props.percent}%, var(--dark-gray) ${props.percent}%)`};
    }

    &::after {
        content: "";
        position: absolute;
        top: -15px;
        left: 0;
    }
    &:first-child::after {
        width: 2px;
        height: 12px;
        background-color: ${(props) => props.isSelected ? "var(--primary-color)" : "var(--dark-gray)"};
    }
    &:nth-child(2)::after,
    &:last-child::after {
        width: 8px;
        height: 8px;
        background-color: var(--white-color);
        border: 2px solid ${(props) => props.isSelected ? "var(--primary-color)" : "var(--dark-gray)"};
    }
    &:nth-child(2)::after {
        border-radius: 50%;
    }
`;

const Booking = Styled(Li)`
    &::before {
        left: 2px;
    }
`;

const CheckedIn = Styled(Li)`
    &::before {
        left: 12px;
    }
`;



/**
 * The Hospitality Detail Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HospitalityItem(props) {
    const { elem, collapsible, action, onAction, canEdit } = props;

    const { hasHospitality } = Store.useState("permission");


    // Do the Render
    if (!hasHospitality) {
        return <React.Fragment />;
    }

    return <DetailList
        isHidden={!elem.id}
        message="HOSPITALITY_SINGULAR"
        collapsible={collapsible}
        action={action}
        onAction={onAction}
        canEdit={canEdit}
    >
        <DetailItem
            isHidden={!elem.hasTime}
            icon="time"
            tooltip="PERIOD_NAME"
        >
            <Ul>
                <Booking percent={elem.bookingPercent} isSelected>
                    {NLS.get("HOSPITALITY_BOOKING")}
                </Booking>
                <CheckedIn percent={elem.checkedInPercent} isSelected={elem.isCheckedIn}>
                    <b>{NLS.get("HOSPITALITY_CHECK_IN")}</b>
                    <span>{elem.fromDate}</span>
                </CheckedIn>
                <Li percent={0} isSelected={elem.isCheckedOut}>
                    <b>{NLS.get("HOSPITALITY_CHECK_OUT")}</b>
                    <span>{elem.toDate}</span>
                </Li>
            </Ul>
        </DetailItem>

        <DetailItem
            icon="status"
            message={elem.statusName}
            tooltip="GENERAL_STATUS"
        />
        <DetailItem
            icon="hotel"
            message={elem.hotelName}
            tooltip="HOTELS_SINGULAR"
        />
        <DetailItem
            icon="id"
            message={elem.externalID}
            tooltip="GENERAL_ID"
        />
        <DetailItem
            icon="room"
            message={elem.roomNumber}
            prefix="HOSPITALITY_ROOM"
        />
        <DetailItem
            icon="nights"
            message={elem.nightsText}
            tooltip="HOSPITALITY_NIGHTS_AMOUNT"
        />
        <DetailItem
            icon="password"
            message={elem.pinMainNumber}
            prefix="HOSPITALITY_PIN_MAIN_NUMBER"
        />
        <DetailItem
            icon="password"
            message={elem.pinSecNumber}
            prefix="HOSPITALITY_PIN_SEC_NUMBER"
        />
        <FieldsItems
            fields={elem.visibleFields}
        />
    </DetailList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HospitalityItem.propTypes = {
    elem        : PropTypes.object.isRequired,
    collapsible : PropTypes.string,
    action      : PropTypes.string,
    canEdit     : PropTypes.bool,
    onAction    : PropTypes.func,
};

export default HospitalityItem;
