import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import useMedia             from "Dashboard/Hooks/Media";

// Components
import IntegrationInputs    from "./IntegrationInputs";
import IntegrationActions   from "./IntegrationActions";
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import Details              from "Dashboard/Components/Details/Details";
import DetailList           from "Dashboard/Components/Details/DetailList";
import DetailItem           from "Dashboard/Components/Details/DetailItem";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Alert                from "Dashboard/Components/Form/Alert";
import Form                 from "Dashboard/Components/Form/Form";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled(Details)`
    --details-height: var(--inters-height-final);

    margin-left: calc(var(--main-gap) * 2);
    padding-left: calc(var(--main-gap) * 2);
    border-left: var(--border-width) solid var(--border-color-light);
`;

const Content = Styled(Form)`
    padding: 8px;
`;

const Footer = Styled.footer`
    position: sticky;
    bottom: 0;
    padding: 8px 8px 0 8px;
    background-color: var(--content-color);
    z-index: 2;
`;



/**
 * The Integration Details
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function IntegrationDetails(props) {
    const { hasExternalTabs } = props;

    const { clientID, actions, publishErrors, languages } = Store.useState("integrationEditor");
    const { editAction } = Store.useAction("integrationEditor");

    const { hasChanges, viewErrors, selectedAction } = Store.useState("integrationState");
    const { setCreate, setHasChanges, setAction, setSelectedAction } = Store.useAction("integrationState");


    // The References
    const dataRef = React.useRef({ name : "", isDisabled : 0 });

    // The Current State
    const [ errors,   setErrors   ] = React.useState({ form : "" });
    const [ language, setLanguage ] = React.useState(languages > 0 ? languages[0].key : "es");

    // The Current Action
    const action = selectedAction ? Utils.getValue(actions, "id", selectedAction) : {};

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();


    // Updates the Data
    React.useEffect(() => {
        if (selectedAction) {
            const values = { name : action.name, isDisabled : action.isDisabled ? 1 : 0 };
            for (const input of action.inputs) {
                if (action.options && action.options[input.name] !== undefined) {
                    values[input.name] = action.options[input.name];
                } else {
                    const initialValue = [ "toggle", "select" ].includes(input.type) ? 0 : "";
                    values[input.name] = input.startValue ?? initialValue;
                }
            }

            const errors = Utils.getValue(publishErrors, "id", selectedAction, "errors");
            if (viewErrors && errors) {
                setErrors({ form : "", ...errors });
            } else {
                setErrors({ form : "" });
            }

            dataRef.current = values;
        }
    }, [ selectedAction ]);

    // Show the Publish Errors
    React.useEffect(() => {
        if (viewErrors) {
            const errors = Utils.getValue(publishErrors, "id", selectedAction, "errors");
            if (errors) {
                setErrors({ form : "", ...errors });
            }
        } else {
            setErrors({ form : "" });
        }
    }, [ viewErrors ]);


    // Handles the Select
    const handleSelect = (actionID) => {
        if (hasChanges) {
            setAction("WARNING");
            return;
        }
        if (actionID === "trigger") {
            setCreate(1, true);
            return;
        }
        if (actionID === "action") {
            setCreate(2, false);
            return;
        }
        if (actionID === "connector") {
            return;
        }

        setSelectedAction(actionID);
        Utils.scrollIntoView(`.integration-action-${actionID}`);
    };

    // Handles the Input Change
    const handleChange = (name, value, newChange = true) => {
        dataRef.current = { ...dataRef.current, [name] : value };
        if (newChange) {
            setHasChanges(true);
        }
    };

    // Handles the Submit
    const handleSubmit = async () => {
        setErrors({ form : "" });
        try {
            const options = { ...dataRef.current };
            delete options.name;
            delete options.isDisabled;
            await editAction({
                actionID   : action.id,
                name       : dataRef.current.name,
                options    : JSON.stringify(options),
                isDisabled : dataRef.current.isDisabled,
            });
            setHasChanges(false);
        } catch (errors) {
            setErrors(errors);
            window.setTimeout(() => {
                if (!Utils.scrollIntoView(".form-error")) {
                    Utils.scrollIntoView(".inputfield-error");
                }
            }, 200);
        }
    };

    // Returns the Error Data
    const getErrorData = (elem) => {
        if (elem.id === "trigger") {
            elem.error = NLS.format(
                "GENERAL_ERROR_FORMAT",
                NLS.get("INTEGRATIONS_ACTIONS_TRIGGER_TITLE"),
                NLS.get("INTEGRATIONS_ACTIONS_ERROR_TRIGGER"),
            );
        } else if (elem.id === "action") {
            elem.error = NLS.format(
                "GENERAL_ERROR_FORMAT",
                NLS.get("GENERAL_ACTION"),
                NLS.get("INTEGRATIONS_ACTIONS_ERROR_ACTION"),
            );
        } else if (elem.id === "connector") {
            elem.error = NLS.format(
                "GENERAL_ERROR_FORMAT",
                NLS.get("CONNECTORS_NAME"),
                NLS.get("INTEGRATIONS_ACTIONS_ERROR_CONNECTOR"),
            );
        } else {
            const name = elem.name || NLS.get(elem.message);
            const text = NLS.pluralize("GENERAL_ERROR_OPTIONS", Utils.count(elem.errors));
            elem.error = NLS.format("GENERAL_ERROR_FORMAT", name, text);
        }
        return elem;
    };


    // Variables
    const showErrors    = Boolean(viewErrors && publishErrors.length > 0);
    const showLanguages = Boolean(action.hasLanguages && languages.length > 1);
    const showContainer = Boolean(showErrors || selectedAction);


    // Do the Render
    if (!showContainer) {
        return <React.Fragment />;
    }
    return <>
        <Container
            hasExternalTabs={hasExternalTabs}
            isInside
            isLarge
            stickyBottom
        >
            {showErrors && <DetailList
                icon="error"
                message="INTEGRATIONS_ACTIONS_WITH_ERRORS"
            >
                {publishErrors.map((elem) => {
                    const { id, error, icon } = getErrorData(elem);
                    return <DetailItem
                        key={id}
                        icon={icon}
                        message={error}
                        isSelected={id === selectedAction}
                        onClick={() => handleSelect(id)}
                    />;
                })}
            </DetailList>}

            {!!selectedAction && <DetailList
                icon={action.icon}
                message={NLS.get(action.message)}
            >
                <IntegrationActions />
                <Content error={errors.form} onSubmit={handleSubmit}>
                    {showLanguages && <TabList
                        variant="lined"
                        selected={language}
                        onClick={setLanguage}
                    >
                        {languages.map(({ key, value }) => <TabItem
                            key={key}
                            message={value}
                            value={key}
                        />)}
                    </TabList>}

                    <Alert
                        isHidden={action.isAllowed}
                        variant="warning"
                        message="INTEGRATIONS_ACTIONS_NOT_ALLOWED"
                    />
                    <InputField
                        name="name"
                        label="INTEGRATIONS_ACTIONS_NAME"
                        value={dataRef.current.name}
                        onChange={handleChange}
                    />
                    <IntegrationInputs
                        data={dataRef.current}
                        errors={errors}
                        language={language}
                        onChange={handleChange}
                        onMedia={handleMediaOpen}
                    />
                    <InputField
                        isHidden={action.isTrigger}
                        type="toggle"
                        name="isDisabled"
                        label="INTEGRATIONS_ACTIONS_IS_DISABLED"
                        value={dataRef.current.isDisabled}
                        onChange={handleChange}
                        withBorder
                    />
                </Content>
                <Footer>
                    <Button
                        variant="primary"
                        message="GENERAL_SAVE"
                        onClick={handleSubmit}
                        fullWidth
                    />
                </Footer>
            </DetailList>}
        </Container>

        <MediaDialog
            open={showMedia}
            clientID={clientID}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
IntegrationDetails.propTypes = {
    hasExternalTabs : PropTypes.bool.isRequired,
};

export default IntegrationDetails;
