import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const List = Styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    ul li {
        padding-left: 16px;
    }
`;

const Item = Styled.div.attrs(({ isSelected }) => ({ isSelected }))`
    display: flex;
    border-radius: var(--border-radius);
    transition: all 0.2s;
    cursor: pointer;

    ${(props) => props.isSelected && "background-color: var(--lighter-gray);"}

    &:hover {
        background-color: var(--lighter-gray);
    }
    &:hover .btn {
        display: flex;
    }
`;

const Name = Styled.span.attrs(({ withSpace }) => ({ withSpace }))`
    flex-grow: 2;
    padding: 8px;
    ${(props) => props.withSpace ? "padding-left: 12px;" : "padding-left: 0;"}
`;

const ItemIcon = Styled(Icon)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 6px;
    border-radius: var(--border-radius);

    &:hover {
        background-color: var(--light-gray);
    }
`;



/**
 * The Document List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DocumentList(props) {
    const { onSelect, documents, level } = props;
    const { documentSlug } = Navigate.useParams();

    const { collapsed } = Store.useState("document");
    const { toggleCollapsed } = Store.useAction("document");


    // Do the Render
    if (!documents || !documents.length) {
        return <React.Fragment />;
    }
    return <List>
        {documents.filter((elem) => elem.isVisible).map(({ id, name, slug, children }) => <li key={id}>
            <Item isSelected={slug === documentSlug}>
                {children.length > 0 && <ItemIcon
                    icon={collapsed.has(id) ? "closed" : "expand"}
                    onClick={() => toggleCollapsed(id)}
                />}
                <Name
                    withSpace={!children.length}
                    onClick={() => onSelect(id)}
                >{name}</Name>
            </Item>

            {!collapsed.has(id) && <DocumentList
                onSelect={onSelect}
                documents={children}
                level={level + 1}
            />}
        </li>)}
    </List>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DocumentList.propTypes = {
    onSelect  : PropTypes.func.isRequired,
    documents : PropTypes.array.isRequired,
    level     : PropTypes.number,
};

/**
 * The Default Properties
 * @typedef {Object} defaultProps
 */
DocumentList.defaultProps = {
    level : 0,
};

export default DocumentList;
