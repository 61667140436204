import React                from "react";

// Components
import TicketList           from "./Ticket/TicketList";
import TicketTypeList       from "./TicketType/TicketTypeList";
import AlertTypeList        from "./AlertType/AlertTypeList";
import WaitlistList         from "./Waitlist/WaitlistList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Setup Container
 * @returns {React.ReactElement}
 */
function SetupContainer() {
    return <>
        <SecondaryNav message="GENERAL_SUPPORT" icon="support">
            <NavigationList>
                <NavigationItem
                    message="TICKETS_NAME"
                    url="TICKETS"
                    icon="ticket"
                />
            </NavigationList>
            <NavigationList>
                <NavigationItem
                    message="TICKET_TYPES_NAME"
                    url="TICKET_TYPES"
                    icon="ticket-type"
                />
                <NavigationItem
                    message="TICKET_ALERT_TYPES_NAME"
                    url="ALERT_TYPES"
                    icon="alert-type"
                />
                <NavigationItem
                    message="TICKET_WAITLISTS_NAME"
                    url="WAITLISTS"
                    icon="waitlist"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="TICKETS"      component={TicketList}     />
            <AdminRoute url="TICKET_TAB"   component={TicketList}     />
            <AdminRoute url="TICKET"       component={TicketList}     />
            <AdminRoute url="TICKET_TYPES" component={TicketTypeList} />
            <AdminRoute url="ALERT_TYPES"  component={AlertTypeList}  />
            <AdminRoute url="WAITLISTS"    component={WaitlistList}   />
        </Router>
    </>;
}

export default SetupContainer;
