import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled.section`
    display: flex;
    border: 1px solid var(--input-border-color);
    border-radius: var(--border-radius);

    .icon {
        margin: 0;
    }
    .btn {
        border: none;
    }
    .btn:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .btn:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .btn-content {
        display: block !important;
    }
`;

const Content = Styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    padding: 5px 0;
    color: var(--primary-color);
    font-size: 12px;
    background: var(--white-color);
    border-radius: 0;
    border-left: var(--border-width) solid var(--border-color-light) !important;
    border-right: var(--border-width) solid var(--border-color-light) !important;
`;



/**
 * The Zoom
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Zoom(props) {
    const { zoom, onMinus, onPlus, onReset } = props;


    // Do the Render
    return <Container>
        <Button
            variant="white"
            icon="minus"
            onClick={onMinus}
            isSmall
        />
        <Content
            variant="white"
            message={`${zoom}%`}
            onClick={onReset}
            isSmall
        />
        <Button
            variant="white"
            icon="plus"
            onClick={onPlus}
            isSmall
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Zoom.propTypes = {
    zoom    : PropTypes.number,
    onMinus : PropTypes.func.isRequired,
    onPlus  : PropTypes.func.isRequired,
    onReset : PropTypes.func.isRequired,
};

export default Zoom;
