import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import { Dashboard }        from "Utils/API";



// The initial State
const initialState = {
    loading     : true,
    error       : false,
    edition     : 0,
    reports     : [],
    dayLabels   : [],
    reportTypes : [],
    filters     : {},
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_LOADING" });
    },

    /**
     * Fetches the Dashboard List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0) {
        const params = {};
        if (type === "CLIENT") {
            params.clientID = elemID;
        } else if (type === "PARTNER") {
            params.partnerID = elemID;
        }

        const data = await Dashboard.getReports(params);

        dispatch({ type : "DASHBOARD_REPORTS", data });
        dispatch({ type : "DASHBOARD_FILTER_DATA", data });
    },

    /**
     * Saves the Dashboard Reports
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async saveReports(dispatch, params) {
        const data = await Dashboard.saveReports(params);
        data.sort = {
            filter : params.filter,
        };
        dispatch({ type : "DASHBOARD_REPORTS", data });
        dispatch({ type : "DASHBOARD_FILTER_DATA", data });
        return data;
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_REPORTS")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_LOADING":
        return {
            ...state,
            loading     : true,
        };

    case "DASHBOARD_REPORTS":
        return {
            ...state,
            loading     : false,
            error       : false,
            reports     : action.data.reports,
            dayLabels   : action.data.dayLabels,
            reportTypes : action.data.reportTypes,
            filters     : action.data.filters,
        };

    case "DASHBOARD_FILTERED":
        return {
            ...state,
            edition     : state.edition + 1,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
