import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";



// Styles
const Social = Styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Title = Styled.h3`
    margin: 16px 0 0 0;
    text-align: center;
    color: var(--font-color);
    font-weight: 400;
`;



/**
 * The Auth Social
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AuthSocial(props) {
    const { message, children } = props;


    // Do the Render
    return <Social>
        <Title>{NLS.get(message)}</Title>
        {children}
    </Social>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AuthSocial.propTypes = {
    message  : PropTypes.string.isRequired,
    children : PropTypes.any,
};

export default AuthSocial;
