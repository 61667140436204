import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardPartnerPage from "Components/App/Dashboard/DashboardPartnerPage";
import ClientList           from "Components/App/Admin/Client/ClientList";
import ClientContainer      from "Components/App/Admin/Client/ClientContainer";
import UserList             from "Components/App/Client/User/UserList";
import SubscriptionList     from "Components/App/Admin/Subscription/SubscriptionList";
import TicketList           from "Components/App/Support/Ticket/TicketList";
import DocumentPage         from "Components/App/Support/Document/DocumentPage";
import ConfigContainer      from "Components/App/Config/ConfigContainer";
import ProfileContainer     from "Components/App/Profile/ProfileContainer";

// Dashboard
import PartnerRoute         from "Components/Utils/Route/PartnerRoute";
import Router               from "Dashboard/Components/Route/Router";



/**
 * The Partner Router
 * @returns {React.ReactElement}
 */
function PartnerRouter() {
    const { isPartner } = Store.useState("auth");


    // Do the Render
    if (!isPartner) {
        return <React.Fragment />;
    }
    return <Router type="PARTNER">
        <PartnerRoute
            url="DASHBOARD"
            component={DashboardPartnerPage}
        />
        <PartnerRoute
            url="CLIENTS"
            component={ClientList}
        />
        <PartnerRoute
            url="CLIENT"
            component={ClientContainer}
        />
        <PartnerRoute
            url="CLIENT_SETTINGS"
            component={ConfigContainer}
        />

        <PartnerRoute
            url="USERS"
            component={UserList}
        />
        <PartnerRoute
            url="USER"
            component={UserList}
        />

        <PartnerRoute
            url="SUBSCRIPTIONS"
            component={SubscriptionList}
        />

        <PartnerRoute
            url="TICKETS"
            component={TicketList}
        />
        <PartnerRoute
            url="TICKET_TAB"
            component={TicketList}
        />
        <PartnerRoute
            url="TICKET"
            component={TicketList}
        />
        <PartnerRoute
            url="DOCUMENTS"
            component={DocumentPage}
        />
        <PartnerRoute
            url="DOCUMENTS_TYPE"
            component={DocumentPage}
        />
        <PartnerRoute
            url="DOCUMENT"
            component={DocumentPage}
        />
        <PartnerRoute
            url="PROFILE"
            component={ProfileContainer}
        />
    </Router>;
}

export default PartnerRouter;
