import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Utils                from "Dashboard/Utils/Utils";
import DocumentType         from "Utils/DocumentType";

// Dashboard
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionOption         from "Dashboard/Components/Header/ActionOption";
import ActionItem           from "Dashboard/Components/Header/ActionItem";



/**
 * The Document Actions
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DocumentActions(props) {
    const { isHidden, updateAction, onDelete } = props;

    const navigate = Navigate.useGoto();

    const { isPartner, isAnyAdmin } = Store.useState("auth");
    const { list, filters, elem, canCreate, canEdit } = Store.useState("document");


    // Handle Language
    const handleLanguage = (language) => {
        navigate("DOCUMENTS", language, filters.documentType);
    };

    // Handle Type
    const handleType = (documentType) => {
        navigate("DOCUMENTS", filters.language, documentType);
    };

    // Handles the Action
    const handleAction = (action) => {
        if (action.isPrint) {
            const document = Utils.getValue(list, "documentID", elem.documentID);
            Utils.print(document.name, document.content);
        } else {
            updateAction(action, elem.documentID);
        }
    };


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <ActionList onAction={handleAction}>
        <ActionItem
            isHidden={!canEdit}
            icon="language"
            message="LANGUAGES_SINGULAR"
            onClick={handleLanguage}
        >
            {NLS.select("LANGUAGES").map(({ key, value }) => <ActionOption
                key={key}
                value={key}
                message={value}
                icon={filters.language === key ? "checkedbox" : "checkbox"}
            />)}
        </ActionItem>

        <ActionItem
            icon="view"
            message="GENERAL_TYPE"
            onClick={handleType}
        >
            <ActionOption
                value={DocumentType.MAIN}
                message="DOCUMENTS_TYPE_MAIN"
                icon={filters.documentType === DocumentType.MAIN ? "checkedbox" : "checkbox"}
            />
            <ActionOption
                isHidden={!isPartner && !isAnyAdmin}
                value={DocumentType.PARTNER}
                message="DOCUMENTS_TYPE_PARTNER"
                icon={filters.documentType === DocumentType.PARTNER ? "checkedbox" : "checkbox"}
            />
            <ActionOption
                isHidden={!isAnyAdmin}
                value={DocumentType.ADMIN}
                message="DOCUMENTS_TYPE_ADMINS"
                icon={filters.documentType === DocumentType.ADMIN ? "checkedbox" : "checkbox"}
            />
            <ActionOption
                value={DocumentType.RELEASES}
                message="DOCUMENTS_TYPE_RELEASES"
                icon={filters.documentType === DocumentType.RELEASES ? "checkedbox" : "checkbox"}
            />
        </ActionItem>

        <ActionItem
            icon="more"
            message="GENERAL_ACTIONS"
        >
            <ActionOption
                isHidden={!canCreate}
                message="DOCUMENTS_CREATE_TITLE"
                action="CREATE"
            />
            <ActionOption
                isHidden={!elem.documentID || !canEdit}
                message="DOCUMENTS_EDIT_TITLE"
                action="EDIT"
            />
            <ActionOption
                isHidden={!elem.documentID || !canEdit}
                message="DOCUMENTS_PRINT_TITLE"
                action="PRINT"
            />
            <ActionOption
                isHidden={!elem.documentID || !canEdit}
                message="DOCUMENTS_DELETE_TITLE"
                action="DELETE"
                onClick={onDelete}
            />
        </ActionItem>
    </ActionList>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DocumentActions.propTypes = {
    isHidden     : PropTypes.bool,
    updateAction : PropTypes.func.isRequired,
    onDelete     : PropTypes.func.isRequired,
};

export default DocumentActions;
